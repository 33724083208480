import ThreadCard from "../../components/ThreadCard";
import SearchBar from "../../components/SearchBar";
import * as Unicons from '@iconscout/react-unicons';
import React, {useEffect, useState} from "react";
import ApiRequest from "../../classes/ApiRequest";
import {useNavigate} from "react-router-dom";
import ThreadTagPicker from "../../components/ThreadTagPicker";
import CategoryButton from "../../components/CategoryButton";
import BottomNavBar from "../../components/BottomNavBar";

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const ForumsPage = (props) => {

    const labelStyles = {
        fontSize: "12px"
    }

    const navigator = useNavigate()

    const searchState = useState(false)
    const [searchInput, setSearchInput] = searchState

    const [threads, setThreads] = useState(null)

    const [tagPickerVisible, setTagPickerVisible] = useState(false)

    const tagState = useState([])
    const [tags, setTags] = tagState

    useEffect(() => {
        const apiRequest = new ApiRequest('POST', `${process.env.REACT_APP_API_URL}/api/forums`)
        apiRequest.sendRequest()
            .then(result => setThreads(() => JSON.parse(result).data))
            .catch(error => console.log('Error@ForumsPage', error))
    }, []);

    useEffect(() => {
        console.log(tags)
        const apiRequest = new ApiRequest('POST', `${process.env.REACT_APP_API_URL}/api/forums`, JSON.stringify({"categories": tags.map(tag => tag.id)}));
        apiRequest.sendRequest()
            .then(result => {
                console.log('result', JSON.parse(result))
                setThreads(() => JSON.parse(result).data)
            })
            .catch(error => console.log('Error@ResourcesPage', error));
    }, [tags])

    useEffect(() => {
        if (searchInput || searchInput === '') {
            let timer;
            clearTimeout(timer);

            timer = setTimeout(() => {
                const apiRequest = new ApiRequest('POST', `${process.env.REACT_APP_API_URL}/api/forums`, JSON.stringify({"search": searchInput}));
                apiRequest.sendRequest()
                    .then(result => {
                        console.log('result', JSON.parse(result))
                        setThreads(() => JSON.parse(result).data)
                    })
                    .catch(error => console.log('Error@ResourcesPage', error));
            }, 1000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [searchInput]);

    const tagChangeHandler = evt => {
        if (!tags.some((tag) => tag.id === evt.target.name)) {
            setTags((prevState) => {
                return [...prevState, {id: evt.target.name, name: evt.target.innerHTML}]
            })
            evt.target.style.opacity = '0.2'
        } else {
            setTags((prevState) => {
                return [...prevState.filter((obj) => obj.id !== evt.target.name)]
            })
            evt.target.style.opacity = '1'
        }
    }

    const removeTagHandler = id => {
        setTags((prevState) => prevState.filter((tag) => tag.id !== id))
    }

    return (

        <BottomNavBar>
            <div className="col container px-3">

                {tagPickerVisible ? <ThreadTagPicker setTagPickerVisible={setTagPickerVisible}
                                                     tagChangeHandler={tagChangeHandler}
                                                     tagState={tagState}/> : null}

                <div className="bg-body pb-1">
                    <h1 className="fw-bold text-primary-emphasis pt-2">Forum</h1>
                    <SearchBar placeholder="Pretraži" inputState={searchState}></SearchBar>
                    <div className="d-flex justify-content-between mt-3">
                        <div className="text-primary-emphasis fs-4 fw-semibold">Prikaži</div>
                        <div onClick={() => {
                            tagPickerVisible ? setTagPickerVisible(false) : setTagPickerVisible(true)
                        }} className="cursor-pointer">
                            <Unicons.UilSlidersVAlt size="25" className="text-primary-emphasis"/>
                        </div>
                    </div>
                </div>

                <div className="d-flex column-gap-2 overflow-x-scroll w-100 mt-2" >
                    {tags.map(tag =>
                        <div onClick={() => removeTagHandler(tag.id)}>
                            <CategoryButton>{tag.name}</CategoryButton>
                        </div>
                    )}
                </div>

                <div id={'novipost'} onClick={() => navigator('/threads/new')}
                     className="position-fixed d-flex align-items-center text-primary-emphasis bg-primary rounded-3 p-3 cursor-pointer z-2"
                     style={{bottom: '100px', right: '20px'}}>
                    <Unicons.UilPlus size="30" className="text-white"/>
                    <div className="ms-2 text-white">Novi post</div>
                </div>


                <div className="row mb-4 row-gap-2 pb-5">
                    {threads?.map(thread =>
                        <div key={thread.id} className="col-12 px-1">
                            <ThreadCard data={thread}/>
                        </div>
                    ) || <Skeleton height={250} count={5}/>}
                </div>

            </div>
        </BottomNavBar>
    )
}

export default ForumsPage