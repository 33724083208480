import React, { useState } from "react";
import * as Unicons from "@iconscout/react-unicons";
import CustomRadioSelector from "../../components/CustomRadioSelector";

const Modal = () => {
  const nextModal = () => {
    const nextBtn = document.getElementById("openReportModal2");
    nextBtn.click();
  };

  const submitReport = () => {
    const closeModal = document.getElementById("closeModal");
    closeModal.click();
  };

  const [isChecked, setIsChecked] = useState(true); // Set to true for default checked state

  const toggleCheckBox = () => {
    setIsChecked(!isChecked);
  };

  const options = [
    "Govor ili simboli mržnje",
    "Nasilje",
    "Uništavanje materijalnih stvari",
    "Maltretiranje ili uznemiravanje",
    "Krađa",
  ];
  const [selectedValue, setSelectedValue] = useState(null);

  const handleOptionSelect = (option) => {
    setSelectedValue(option);
  };

  return (
    <>
      <div
        className="modal fade"
        id="reportModal1"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content px-2 py-1">
            <div className="modal-header d-block border-0"></div>
            <div className="modal-body pt-0">
              <div>
                <div className="mb-3">
                  <div className="fw-bold fs-5 text-primary-emphasis">
                    Šta želite da prijavite?
                  </div>
                  <div className="fs-6 text-secondary">
                    Odaberite jednu od opcija koja najviše odgovara vašoj
                    prijavi
                  </div>
                </div>

                <div>
                  <CustomRadioSelector
                    options={options}
                    onSelect={handleOptionSelect}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-between border-0">
              <button
                onClick={() => {}}
                type="button"
                style={{ height: "45px" }}
                className="btn btn-outline-secondary text-secondary-emphasis fw-bold col-12 col-md-5"
                data-bs-dismiss="modal"
              >
                Otkaži
              </button>
              <button
                onClick={nextModal}
                type="button"
                style={{ height: "45px" }}
                className="btn btn-primary col-12 col-md-5"
              >
                Dalje
              </button>
              <button
                id="openReportModal2"
                data-bs-target="#reportModal2"
                data-bs-toggle="modal"
                hidden
              ></button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="reportModal2"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content px-2 py-1">
            <div className="modal-header d-block border-0"></div>
            <div className="modal-body py-0">
              <div>
                <div className="mb-3">
                  <div className="fw-bold fs-5 text-primary-emphasis">
                    Opišite vašu prijavu
                  </div>
                  <div className="fs-6 text-secondary">
                    Odaberite korisnika za kojeg ste sigurni da je počinio to
                    djelo
                  </div>
                </div>

                <div>
                  <form>
                    <div className="d-flex flex-column">
                      <label className="pt-3 fw-medium text-primary-emphasis">
                        Ime i prezime počinioca
                      </label>
                      <input
                        type="text"
                        placeholder="Unesite ime i prezime počinioca"
                        className="border rounded-3 p-2 my-1 bg-transparent input-border-none"
                      />
                    </div>

                    <div className="d-flex flex-column">
                      <label className="pt-3 fw-medium text-primary-emphasis">
                        Opis*
                      </label>
                      <textarea
                        rows="5"
                        type="text"
                        placeholder="Opišite prijavu"
                        className="border rounded-3 p-2 my-1 bg-transparent input-border-none"
                        style={{ resize: "none" }}
                      />
                    </div>

                    <div>
                      <label className="pt-3 fw-medium text-primary-emphasis">
                        Foto/video dokaz
                      </label>
                      <div className="rounded-3 border d-flex flex-column align-items-center px-1 py-5 my-1">
                        <div className="bg-secondary bg-opacity-10 rounded-5 p-2">
                          <Unicons.UilCloudUpload
                            style={{ transform: "scaleX(-1)" }}
                            size={35}
                            color="gray"
                          />
                        </div>

                        <div className="text-secondary">
                          <span className="text-primary fw-medium ">
                            Kliknite da bi ste objavi
                          </span>{" "}
                          ili prevucite fajl
                        </div>
                      </div>
                    </div>

                    <div className="d-flex mt-4">
                      <div className="d-flex" onClick={toggleCheckBox}>
                        {isChecked ? (
                          <div className="d-flex align-items-start ">
                            <Unicons.UilCheck
                              size={20}
                              className="text-primary border border-primary rounded-2"
                            />
                          </div>
                        ) : (
                          <div className="d-flex align-items-start ">
                            <Unicons.UilCheck
                              size={20}
                              color="transparent"
                              className="text-primary border border-secondary rounded-2 "
                            />
                          </div>
                        )}

                        <label className="ms-2 fw-medium text-primary-emphasis">
                          Prijavi anonimno
                        </label>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-between border-secondary-subtle border-0">
              <button
                type="button"
                style={{ height: "45px" }}
                data-bs-target="#reportModal1"
                data-bs-toggle="modal"
                className="btn btn-outline-secondary text-secondary-emphasis fw-bold col-12 col-md-5"
                data-bs-dismiss="modal"
              >
                Nazad
              </button>
              <button
                onClick={submitReport}
                type="button"
                style={{ height: "45px" }}
                className="btn btn-primary col-12 col-md-5"
              >
                Potvrdi
              </button>
              <button hidden id={"closeModal"} data-bs-dismiss="modal"></button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
