import NavBar from "../components/NavBar";
import Card from "../components/Card";
import Footer from "../components/Footer";
import {useNavigate} from "react-router-dom";
import {mouseLeaveHandler, mouseMoveHandler} from "../classes/CardScript";
import TeamMemberCard from "../components/TeamMemberCard";
import React from "react";

const LandingPage = () => {

    const navigator = useNavigate()

    return (
        <div className='overflow-x-hidden bg-light user-select-none'>
            <div className="d-flex flex-column min-vh-100">
                <NavBar/>
                <section className='py-5 flex-grow-1' style={{backgroundColor: 'rgba(247, 247, 247, 1)'}}>
                    <div className="container">
                        <div className="row">
                            {/*<img src="./assets/ReadMore/card.png" className={'img-fluid pb-4'} alt=""/>*/}
                            <h1 className="fw-bold text-dark display-3">BetterEdu</h1>
                            <p className="py-3 fs-5 text-secondary">
                                Naša platforma rješava uočene probleme na nekoliko načina. Prvo, kroz mogućnost anonimne prijave vršnjačkog nasilja i oštećene opreme u školi, omogućava se učenicima i profesorima da brzo i efikasno reaguju na ove situacije. Takođe, kroz sistem vrednovanja gdje učenici pomažu jedni drugima na platformi, podstiče se aktivnost i međusobna podrška među učenicima tokom časa. Roditeljima se omogućava da budu uključeni u proces donošenja važnih odluka za školu, što može dovesti do boljeg funkcionisanja škole i većeg zadovoljstva učenika, roditelja i profesora. Sekcija za obavještenja o aktivnostima u školi omogućava transparentnost i lak pristup informacijama.
                            </p>
                        </div>
                    </div>
                </section>
                <Footer/>
            </div>



        </div>
    );
}

export default LandingPage;
