import * as Unicons from '@iconscout/react-unicons';
import ThreadCard from "../../components/ThreadCard";
import {useEffect, useState} from "react";
import ResourceCard from "../../components/ResourceCard";
import ApiRequest from "../../classes/ApiRequest";
import {useNavigate} from "react-router-dom";

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import BottomNavBar from "../../components/BottomNavBar";
const ProfilePage = props => {

    const navigator = useNavigate()
    const [user, setUser] = useState(null)
    const [resources, setResources] = useState(null)
    const [threads, setThreads] = useState(null)

    useEffect(() => {
        const userResourcesRequest = new ApiRequest('GET', `${process.env.REACT_APP_API_URL}/api/user/resources`);
        userResourcesRequest.sendRequest()
            .then(result => setResources(() => JSON.parse(result).data))
            .catch(error => console.log('Error@ProfilePage', error));

        const userThreadsRequest = new ApiRequest('GET', `${process.env.REACT_APP_API_URL}/api/user/forums`);
        userThreadsRequest.sendRequest()
            .then(result => setThreads(() => JSON.parse(result).data))
            .catch(error => console.log('Error@ProfilePage', error));


        const userDataRequest = new ApiRequest('GET', `${process.env.REACT_APP_API_URL}/api/user`);
        userDataRequest.sendRequest()
            .then(result => setUser(() => JSON.parse(result).data))
            .catch(error => console.log('Error@ProfilePage', error));
    }, []);

    const [activeTab, setActiveTab] = useState('Forum');

    const handleTapChange = (tab) => {
        setActiveTab(tab);
    }



    return (
        <BottomNavBar>
            <div className="col p-0 bg-body">
                <div className="fixed-header bg-body">
                    <div className="pt-3 px-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                                <span className="fw-bold text-primary-emphasis fs-5">betterEdu</span>
                            </div>
                            <div className="d-flex gap-3">
                                <Unicons.UilEnvelopeAlt size="30" className="cursor-pointer text-primary-emphasis"/>
                                <Unicons.UilCog size="30" className="cursor-pointer text-primary-emphasis" onClick={() => {navigator("/settings")}}/>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-4">
                            <div>
                                <div
                                    className="text-primary-emphasis fw-bold fs-4 mb-2">{user?.name || <Skeleton/>} {user?.surname || <Skeleton/>}</div>
                                <div className="text-primary-emphasis fw-bold">{user?.username || <Skeleton/>}</div>
                            </div>
                            <div className="d-flex">
                                <img className="rounded-circle" src={user?.photoPath || <Skeleton/>} alt="" style={{width: '80px'}}/>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mt-4 mb-4">
                            <div
                                className="bg-secondary-subtle p-2 w-100 text-center fw-medium me-2 rounded-2 text-primary-emphasis"
                                style={{fontSize: "13px"}}>Učenik/ca 3. razreda
                            </div>
                            <div
                                className="bg-secondary-subtle p-2 w-100 text-center fw-medium ms-2 rounded-2 text-primary-emphasis"
                                style={{fontSize: "13px"}}>{user?.likes || <Skeleton/>} zahvalnica
                            </div>
                        </div>
                        <div>
                            <button
                                onClick={() => navigator('/profile/edit')}
                                className='btn btn-dark w-100 border-1 rounded-3 p-2 fw-bold bg-transparent text-primary-emphasis'
                                style={{fontSize: 12}}>Edit profile
                            </button>
                        </div>
                        <div className="mt-3 text-primary-emphasis pb-3" style={{fontSize: '14px'}}>{user?.bio || <Skeleton count={5}/>}</div>
                    </div>
                </div>

                <div className='d-flex justify-content-around card-container bg-body sticky-bottom'>
                    <button
                        className={`bg-transparent border-0 text-body ${activeTab === 'Forum' ? 'border-bottom border-2 border-white text-primary-emphasis' : 'border-bottom border-1 border-secondary-subtle mb-0'} w-100`}
                        onClick={() => handleTapChange('Forum')}>Forum
                    </button>
                    <button
                        className={`bg-transparent border-0 text-body ${activeTab === 'Resursi' ? 'border-bottom border-2 border-white text-primary-emphasis' : 'border-bottom border-1 border-secondary-subtle mb-0'} w-100`}
                        onClick={() => handleTapChange('Resursi')}>Resursi
                    </button>
                </div>


                {activeTab === 'Forum' && (
                    <div className="col mx-2 pt-3 card-container bg-body px-2 ">
                        {threads?.map(thread =>
                            <ThreadCard data={thread} key={thread.id}/>
                        ) || <Skeleton height={100}/>}
                    </div>
                )}

                {activeTab === 'Resursi' && (
                    <div className="col mx-2 pt-3 card-container bg-body px-2">
                        {resources?.map(resource =>
                            <ResourceCard data={resource} key={resource.id}/>) || <Skeleton height={100}/>}
                    </div>
                )}

                <div style={{height: "85px"}}></div>
            </div>
        </BottomNavBar>
    )
}

export default ProfilePage