import CategoryButton from "./CategoryButton";
import * as Unicons from '@iconscout/react-unicons';
import {useNavigate} from "react-router-dom";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const ResourceCard = (p) => {

    const navigator = useNavigate()

    return (
        <div onClick={() => navigator(`/resource/${p.data.id}`)}
             className="bg-secondary-subtle mt-2 py-3 rounded-3 cursor-pointer h-100 d-flex flex-column justify-content-betwee hover-effect">
            <div>
                <div className="d-flex overflow-x-scroll pe-3">
                    {p.data.categories.map((category, index) =>
                        <div key={category.id}
                             className={index === 0 ? 'ms-3 me-1' : 'mx-1'}>
                            <CategoryButton color={category.color}>{category.name || <Skeleton/>}</CategoryButton>
                        </div>)}
                </div>
                <div className="px-3 text-primary-emphasis mt-2">
                    <div className="fw-medium" style={{fontSize: "16px"}}>{p.data.title}</div>
                </div>
            </div>
            <div className="px-3 text-primary-emphasis mt-2 d-flex justify-content-between align-items-center w-100">
                <div>
                    <img src={p.data.user.photoPath} className="rounded-circle" height="26" alt=""/>
                    <span className="ps-2 fw-semibold" style={{fontSize: "12px"}}>{p.data.user.name + ' ' + p.data.user.surname}</span>
                </div>
                <div className="d-flex align-items-center">
                    <div>
                        <span className="me-1 fw-semibold" style={{fontSize: "12px"}}>{p.data.likes}</span>
                        <Unicons.UilHeart size="20" className="hov-heart"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResourceCard