import PropTypes from "prop-types";

const CategoryButton = (props) => {



    const styles = {
        fontSize: "12px",
        //you can pass a color through props
        backgroundColor: `rgba(${props.color}, 0.1)`,
        border: `solid 1px rgb(${props.color})`
    }

    return (
        <button type="button" name={props.id ? props.id : null} onClick={props.onClick} style={styles} className="fw-medium user-select-none  rounded-4 px-2 py-1">
            {props.children}
        </button>
    )
}

CategoryButton.propTypes = {
    children: PropTypes.string,
    onClick: PropTypes.func,
    color: PropTypes.string
}

CategoryButton.defaultProps = {
    color: "0, 133, 255"
}

export default CategoryButton