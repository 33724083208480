import {useState} from "react";
import ForumsPage from "../pages/Forum/ForumsPage";
import * as Unicons from '@iconscout/react-unicons';
import ResourcesPage from "../pages/Resource/ResourcesPage";
import ProfilePage from "../pages/Profile/ProfilePage";
import ReportsPage from "../pages/Report/ReportsPage";
import {useNavigate, useParams} from "react-router-dom";

const HomeLayout = (props) => {

    const params = useParams()
    const navigate = useNavigate()

    const labelStyles = {
        fontSize: "12px"
    }

    const [currentPage, setCurrentPage] = useState(params.page)



    const navigationHandler = (value) => {
        setCurrentPage(value)
        navigate(`/home/${value}`)
    }

    return (
        <div className="min-vh-100 p-0 m-0 row align-content-between bg-body" data-bs-theme={localStorage.getItem('theme')}>
            {/* Sadrzaj strana ide ovde */}
            {currentPage === 'forum' ? <ForumsPage/> : null}
            {currentPage === 'resources' ? <ResourcesPage /> : null}
            {currentPage === 'reports' ? <ReportsPage /> : null}
            {currentPage === 'account' ? <ProfilePage /> : null}

            <div className="fixed-bottom bg-body d-flex justify-content-between text-center pb-2 pt-3 column-gap-2">

                <div onClick={() => navigationHandler('forum')}
                     className="row m-0 cursor-pointer">

                    {currentPage === 'forum' ?
                        <Unicons.UilCommentsAlt className="text-primary-emphasis" size="25"/> :
                        <Unicons.UilCommentsAlt size="25" color="gray"/>}

                    <span className={currentPage === 'forum' ? 'text-primary-emphasis' : 'text-secondary'}
                          style={labelStyles}>Forum</span>
                </div>

                <div onClick={() => navigationHandler('resources')}
                     className="row m-0 cursor-pointer">

                    {currentPage === 'resources' ?
                        <Unicons.UilNotebooks className="text-primary-emphasis" size="25"/> :
                        <Unicons.UilNotebooks size="25" color="gray"/>}

                    <span className={currentPage === 'resources' ? 'text-primary-emphasis' : 'text-secondary'}
                          style={labelStyles}>Resursi</span>
                </div>

                <div onClick={() => navigationHandler('reports')}
                     className="row m-0 cursor-pointer">

                    {currentPage === 'reports' ?
                        <Unicons.UilExclamationOctagon className="text-primary-emphasis" size="25"/> :
                        <Unicons.UilExclamationOctagon size="25" color="gray"/>}

                    <span className={currentPage === 'reports' ? 'text-primary-emphasis' : 'text-secondary'}
                          style={labelStyles}>Prijave</span>
                </div>

                <div onClick={() => navigationHandler('account')}
                     className="row m-0 cursor-pointer">

                    {currentPage === 'account' ?
                        <Unicons.UilUser className="text-primary-emphasis" size="25"/> :
                        <Unicons.UilUser size="25" color="gray"/>}

                    <span className={currentPage === 'account' ? 'text-primary-emphasis' : 'text-secondary'}
                          style={labelStyles}>Nalog</span>
                </div>
            </div>
        </div>

    )
}

export default HomeLayout