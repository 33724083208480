import {useState} from "react";

export default function NavBarModal() {

    const [isHovering, setIsHovering] = useState(false);

    const handleMouseEnter = () => {
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };

    return(

        <div className="modal fade" id="exampleModal" tabIndex="150" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5 text-primary-emphasis" id="exampleModalLabel">Kreiraj</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                           <div className="col-4">
                               <a href="/threads/new" className={"d-flex align-items-stretch"} style={{textDecoration:'none'}}>
                                   <div className="card">
                                       <div className="card-body">
                                           <h5 className="card-title text-center fw-semibold">Forum</h5>
                                           <p className="card-text text-center">Kreiraj novi post na forumu.</p>
                                       </div>
                                   </div>
                               </a>
                           </div>
                            <div className="col-4 d-flex align-items-stretch">
                                <a href="/resources/new" className={"d-flex align-items-stretch"} style={{textDecoration:'none'}}>
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="card-title text-center fw-semibold">Resursi</h5>
                                            <p className="card-text text-center">Kreiraj novi resurs.</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-4 d-flex align-items-stretch">
                                <a href="/reports/new" className={"d-flex align-items-stretch"} style={{textDecoration:'none'}}>
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="card-title text-center fw-semibold">Prijave</h5>
                                            <p className="card-text text-center">Podnesi novu prijavu.</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}