const Validator = (toValidate, rules) => {
    let output = {
        passed: true,
        messages: {},
        validated: {}
    }

    Object.entries(rules).forEach((elementRulesE) => {

        let elementRules = elementRulesE[1]
        let name = elementRulesE[0]
        let element = toValidate[name]

        if (element) {

            if (elementRules.includes('required')) {

                if (element.trim().length > 0) {
                    output.validated[name] = element.trim()
                } else {
                    output.passed = false
                    // output.messages[name] = `Polje ${name} je obavezno.`
                    output.messages[name] = `Polje je obavezno.`
                }
            }

            if (elementRules.includes('email')) {

                if (element.trim().includes('@')) {
                    output.validated[name] = element.trim()
                } else {
                    output.passed = false
                    // output.messages[name] = `Format polja ${name} nije validan.`
                    output.messages[name] = `Format polja nije validan.`
                }
            }

            if (elementRules.find(value => /max:[0-9]+/i.test(value))) {

                let rule = elementRules[elementRules.indexOf(elementRules.find(value => /max:[0-9]+/i.test(value)))]
                let length = rule.split(':')[1]

                if (element.trim().length < length) {
                    output.validated[name] = element.trim()
                } else {
                    output.passed = false
                    // output.messages[name] = `Polje ${name} mora sadžati manje od ${length} karaktera`
                    output.messages[name] = `Polje mora sadžati manje od ${length} karaktera`
                }
            }

            if (elementRules.find(value => /min:[0-9]+/i.test(value))) {

                let rule = elementRules[elementRules.indexOf(elementRules.find(value => /min:[0-9]+/i.test(value)))]
                let length = rule.split(':')[1]

                if (element.trim().length > length) {
                    output.validated[name] = element.trim()
                } else {
                    output.passed = false
                    // output.messages[name] = `Polje ${name} mora sadžati najmanje ${length} karaktera`
                    output.messages[name] = `Polje mora sadžati najmanje ${length} karaktera`
                }
            }

            if (elementRules.includes('alpha_dash')) {

                if (/^[a-z0-9_-]+$/i.test(element.trim())) {
                    output.validated[name] = element.trim()
                } else {
                    output.passed = false
                    // output.messages[name] = `Polje ${name} ne smije da sadrži razmak.`
                    output.messages[name] = `Polje je pogrešnog formata`
                }
            }

            if (elementRules.includes('lowercase')) {

                if (element === String(element).toLowerCase()) {
                    output.validated[name] = element.trim()
                } else {
                    output.passed = false
                    // output.messages[name] = `Polje ${name} ne smije da sadrži velika slova.`
                    output.messages[name] = `Polje ne smije da sadrži velika slova.`
                }
            }

            if (elementRules.includes('confirmed')) {

                if (element === toValidate[`${name}_confirmation`]) {
                    output.validated[name] = element.trim()
                } else {
                    output.passed = false
                    // output.messages[name] = `Potvrda polja ${name} se ne poklapa`;
                    output.messages[name] = `Potvrda polja se ne poklapa`;
                }
            }
        } else {
            output.passed = false
            // output.messages[name] = `Polje ${name} je obavezno.`
            output.messages[name] = `Polje je obavezno.`
        }

    })

    return output
}

// // validator test
//
// const dummyInput = {
//     name: 'Ramiz',
//     surname: 'Ramovic',
//     username: 'ramizramizovic',
//     email: 'oarman@gmail.com',
//     password: 'password',
//     password_confirmation: 'password',
//     device: ''
// }
//
// let validator = Validator(dummyInput, {
//     name: ['required', 'max:256'],
//     surname: ['required', 'max:10'],
//     username: ['required', 'max:90', 'alpha_dash', 'lowercase'],
//     email: ['required', 'email'],
//     password: ['required', 'min:8', 'max:255', 'confirmed'],
//     device: ['required']
// })
//
// console.log(validator)
export default Validator