import DateInput from "../../components/DateInput";
import * as Unicons from "@iconscout/react-unicons";
import ReportCard from "../../components/ReportCard";
import Modal from "./Modal";
import BottomNavBar from "../../components/BottomNavBar";

const ReportsPage = () => {

    const dummy_report = [
        {
            description: 'Podnešena je prijava',
            date: '19 April 2023.',
            color: 'rgba(0, 133, 255, 1)'
        },
        {
            description: 'Podnešena je prijava',
            date: '19 April 2023.',
            color: 'rgba(255, 0, 0, 1)'
        },
        {
            description: 'Podnešena je prijava',
            date: '19 April 2023.',
            color: 'rgba(255, 230, 5, 1)'
        },
        {
            description: 'Podnešena je prijava',
            date: '19 April 2023.',
            color: 'rgba(26, 255, 133, 1)'
        }
    ]


    return (
        <BottomNavBar>
            <div className="col container px-3">
                <div className="bg-body pb-1">
                    <h1 className="fw-bold text-primary-emphasis pt-2">Prijave</h1>
                    <DateInput placeholder="Izaberite datum"></DateInput>
                    <div className="d-flex justify-content-between mt-3">
                        <div className="text-primary-emphasis fs-4 fw-semibold">Prikaži</div>
                        <div>
                            <Unicons.UilSlidersVAlt size="25" className="text-primary-emphasis"/>
                        </div>
                    </div>
                </div>

                <Modal />

                <div className="position-fixed d-flex align-items-center text-primary-emphasis bg-primary rounded-3 p-3 cursor-pointer"
                     data-bs-toggle="modal" data-bs-target="#reportModal1" style={{bottom: '100px', right: '20px'}}>
                    <Unicons.UilPlus size="30" className="text-white"/>
                    <div className="ms-2 text-white">Nova prijava</div>
                </div>

                <div className="col mb-4">
                    {dummy_report.map((report, index) =>
                        <ReportCard key={index}
                                    description={report.description}
                                    date={report.date}
                                    color={report.color}
                        />
                    )}
                </div>
            </div>
        </BottomNavBar>
    );
}

export default ReportsPage;