import {Link} from "react-router-dom";
import Validator from "../../classes/Validator";
import {useState} from "react";
import Swal from 'sweetalert2'
import ApiRequest from "../../classes/ApiRequest";
const ResetPage = () => {

    let initValidator = {messages: {}};

    const [validator, setValidator] = useState(initValidator)

    const [userInput, setUserInput] = useState()

    const formChangeHandler = evt => {
        setValidator(initValidator);
        const name = evt.target.name
        const value = evt.target.value
        setUserInput((prevState) => {
            return {...prevState, [name]: value.trimStart()}
        })
    }

    const formSubmitHandler = (e) => {
        e.preventDefault()

        e.target.classList.add('was-validated')

        let validator = Validator(userInput, {
            email: ['required', 'email'],
        })

        setValidator(validator);

        if (!e.target.checkValidity()) {
            e.stopPropagation();
        } else if (validator.passed) {

            const raw = JSON.stringify({
                "email": `${e.target.email.value}`
            });

            const apiRequest = new ApiRequest('POST', `${process.env.REACT_APP_API_URL}/api/password/email`, raw)
            apiRequest.editHeader("Authorization", `Bearer ${process.env.REACT_APP_API_KEY}`)
            apiRequest.sendRequest()
                .then(result => {
                    const parsed = JSON.parse(result)
                    // console.log(parsed)

                    return Swal.fire({
                        icon: parsed.errors ? 'error' : 'success',
                        title: parsed.message,
                    })

                })
                .catch(error => null);

        }
    }
    
    return (
        <main className="container-fluid">
            <div className="row min-vh-100">
                <div className="col-12 align-self-center">
                    <div className="container w-495">
                        <form onSubmit={formSubmitHandler} noValidate={true}>
                            <h2 className="mb-2 fw-bold">Pronađite račun</h2>
                            <h5 className="mb-4">Unesite email adresu da bi započeli proces resetovanja</h5>
                            <div>
                                <div className="m-0 mb-3">
                                    <input required type="email"
                                           name="email"
                                           placeholder="E-mail"
                                           onChange={formChangeHandler}
                                           className="form-control form-control-lg rounded-3 border-0"/>
                                    <div className="invalid-feedback">{validator.messages.email}</div>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary w-100 mb-3 fw-bold fs-5 rounded-3 py-2">Pošalji zahtjev
                            </button>
                            <h6 className="fw-medium">Znate šifru? <Link to="/login"
                                                                         className="text-primary fw-medium text-decoration-none">Prijavite
                                se</Link></h6>
                        </form>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default ResetPage