import * as Unicons from "@iconscout/react-unicons";
import {useNavigate} from "react-router-dom";
import BottomNavBar from "../../components/BottomNavBar";

const ReportDetailsPage = () => {
    const navigator = useNavigate()

    return (
        <BottomNavBar>
            <div className="container-fluid bg-body min-vh-100 pb-5" data-bs-theme={localStorage.getItem('theme')}>
                <div className="row border-bottom border-secondary-subtle py-1 sticky-top bg-body">
                    <div className="col-9 d-flex align-items-center p-0">
                        <div onClick={() => navigator(-1)}>
                            <Unicons.UilArrowLeft size="40" className="ms-1 text-primary-emphasis cursor-pointer"/>
                        </div>
                        <div className="ms-1 fw-semibold text-primary-emphasis"
                             style={{fontSize: '16px'}}>Prijava</div>
                    </div>
                </div>
                <div className="d-flex align-items-center mt-4 mb-3">
                    <div className='d-flex align-items-center'>
                        <img src="/assets/dummy_pictures/Icon.svg"  alt=""/>
                    </div>
                    <div className="ms-3 fw-medium text-primary-emphasis"  style={{fontSize: '14px'}}>Prijava je podnešena</div>
                </div>
                <div className='row mb-3 p-0'>
                    <h1 className='fw-bold text-primary-emphasis'>Prijava #0001</h1>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center column-gap-2">
                        <div className="fw-medium text-primary-emphasis"
                             style={{fontSize: '16px'}}>Tip prijave: <span className="text-danger">Nasilje</span></div>
                    </div>
                    <div className="text-primary fw-medium"
                         style={{fontSize: '16px'}}>19 April 2023.
                    </div>
                </div>
                <div className='mt-4'>
                    <p className='text-primary-emphasis fw-medium'>
                        Poštovani,

                        <br/>

                        Ovim putem želim prijaviti nasilje koje se dogodilo prema meni u određenom vremenskom periodu. Naime, osoba NN je upućivala prijetnje i fizički me napadala, zbog čega se osjećam ugroženo/na. Molim Vas da ovo shvatite ozbiljno i da se poduzmu potrebni koraci kako bi se osigurala moja sigurnost.

                        <br/><br/>

                        S poštovanjem
                    </p>
                </div>
                <div className='row g-3'>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <div className='card border-0' style={{height: '200px', backgroundColor: '#F2F4F7'}}></div>
                    </div>
                </div>

                <hr className="border-secondary-subtle border-2" />

                <div>
                    <div className={'d-flex align-items-center mb-4'}>
                        <div>
                            <img src="/assets/dummy_pictures/Profile.svg" alt="logo" style={{width: '32px'}}/>
                        </div>
                        <p className={'m-0 ms-2 text-primary-emphasis fw-semibold'}>Administrator</p>
                        <p className={'m-0 text-primary fw-bold'}>
                            <span className={'mx-2'}>•</span>
                            19 April 2023.
                        </p>
                    </div>
                    <p className='text-primary-emphasis fw-medium mb-0'>
                        Poštovani/a,
                        <br/>
                        Zahvaljujemo Vam na prijavi nasilja koje ste nam dostavili. Vaša prijava je uvažena te su poduzete sve mjere kako bi se osigurala Vaša sigurnost.
                        U ovom slučaju, nadležne službe su odmah reagirale na Vašu prijavu i poduzele sve potrebne korake kako bi se utvrdila odgovornost počinitelja ovog nasilja te kako bi se zaštitila Vaša i sigurnost drugih.
                        <br/><br/>
                        S poštovanjem
                    </p>
                </div>
            </div>
        </BottomNavBar>
    );
}

export default ReportDetailsPage;