const Footer = () => {
    return(
        <section style={{backgroundColor: 'rgba(24, 24, 24, 1)'}} className='py-4'>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-sm-6 text-center text-md-start">
                        <h2 className="fw-bold text-white">betterEdu</h2>
                    </div>
                    <div className="col-md-6 col-sm-6 text-center text-md-end d-md-flex justify-content-end align-items-center">
                        <a href="mailto:contact@betteredu.me" className="text-decoration-none" target="_blank" style={{color: '#7D7D7D'}}>contact@betteredu.me</a>
                    </div>
                </div>
                <hr className="border "/>
                <div className="row text-center text-md-start">
                    <div className="col-md-6">
                        <a href="#" style={{color: '#7D7D7D'}} className="text-decoration-none me-3">Početna</a>
                        <a href="#" style={{color: '#7D7D7D'}} className="text-decoration-none me-3">Saznaj više</a>
                        <a href="#" style={{color: '#7D7D7D'}} className="text-decoration-none">API</a>
                    </div>
                    <div className="col-md-6 text-center text-md-end pt-2 pt-md-0">
                        <a href="https://status.betteredu.me" className="text-decoration-none me-3" target="_blank" style={{color: '#7D7D7D'}}>
                            <img src="assets/LandingPage/online.svg" alt=""/> <span className={"text-white ms-1"}> Status</span>
                        </a>
                        <a href="https://betterstack.com" className="text-decoration-none" target="_blank" style={{color: '#7D7D7D'}}>Powered by
                            <img src="assets/LandingPage/betterslack.svg" className="ms-2" alt=""/>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Footer;