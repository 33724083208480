import {BrowserRouter, Routes, Route} from "react-router-dom";
import './assets/bootstrap/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import {useEffect} from "react";

import LoginPage from './pages/Auth/LoginPage';
import RegisterPage from "./pages/Auth/RegisterPage";
import ResetPage from "./pages/Auth/ResetPage";
import HomeLayout from "./components/HomeLayout";
import LandingPage from "./pages/LandingPage";
import ForumPage from "./pages/Forum/ForumPage";
import ResourcePage from "./pages/Resource/ResourcePage";
import NewResourcePage from "./pages/Resource/NewResourcePage";
import NotFound from "./pages/NotFound";
import ProtectedRoute from "./middleware/ProtectedRoute";
import NewForumPage from "./pages/Forum/NewForumPage";
import EditResourcePage from "./pages/Resource/EditResourcePage";
import EditForumPage from "./pages/Forum/EditForumPage";
import ReportDetailsPage from "./pages/Report/ReportDetailsPage";
import EditProfilePage from "./pages/Profile/EditProfilePage";

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import ForumsPage from "./pages/Forum/ForumsPage";
import ResourcesPage from "./pages/Resource/ResourcesPage";
import ReportsPage from "./pages/Report/ReportsPage";
import ProfilePage from "./pages/Profile/ProfilePage";
import SettingsPage from "./pages/Profile/SettingsPage";
import ReadMore from "./pages/ReadMore";

function App() {



    useEffect(() => {


        function isDarkModePreferred() {
            return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
        }
        if (isDarkModePreferred()) {
            localStorage.setItem('theme', 'dark');
        } else {
            localStorage.setItem('theme', 'light');
        }

    }, []);

    return (
        <SkeletonTheme baseColor={localStorage.getItem('theme') == 'light' ? "#ebebeb" : "#202020" }
                       highlightColor={localStorage.getItem('theme') == 'light' ? "#f5f5f5" : "#444"}>
            <BrowserRouter>
                <Routes>

                    <Route index element={<LandingPage/>}/>
                    <Route path='/readmore' element={<ReadMore/>}/>
                    <Route path='/login' element={<LoginPage/>}/>

                    <Route path='/register' element={<RegisterPage/>}/>
                    <Route path='/reset' element={<ResetPage/>}/>

                    <Route path='/home/:page' element={<ProtectedRoute><HomeLayout/></ProtectedRoute>}/>

                    <Route path='/resources' element={<ProtectedRoute><ResourcesPage/></ProtectedRoute>}/>
                    <Route path='/resource/:id/edit' element={<ProtectedRoute><EditResourcePage/></ProtectedRoute>}/>
                    <Route path='/resource/:id' element={<ProtectedRoute><ResourcePage/></ProtectedRoute>}/>
                    <Route path='/resources/new' element={<ProtectedRoute><NewResourcePage/></ProtectedRoute>}/>

                    <Route path='/threads' element={<ProtectedRoute><ForumsPage/></ProtectedRoute>}/>
                    <Route path='/thread/:id/edit' element={<ProtectedRoute><EditForumPage/></ProtectedRoute>}/>
                    <Route path='/threads/new' element={<ProtectedRoute><NewForumPage/></ProtectedRoute>}/>
                    <Route path='/thread/:id' element={<ProtectedRoute><ForumPage/></ProtectedRoute>}/>
                    <Route path='/thread/reply/:parent_id' element={<ProtectedRoute><NewForumPage/></ProtectedRoute>}/>

                    <Route path='/profile' element={<ProtectedRoute><ProfilePage/></ProtectedRoute>}/>
                    <Route path='/profile/edit' element={<ProtectedRoute><EditProfilePage/></ProtectedRoute>}/>

                    <Route path='/settings' element={<ProtectedRoute><SettingsPage /></ProtectedRoute>}></Route>

                    <Route path='/reports' element={<ProtectedRoute><ReportsPage/></ProtectedRoute>}/>
                    <Route path='/report/details' element={<ProtectedRoute><ReportDetailsPage/></ProtectedRoute>}/>

                    <Route path='*' element={<NotFound/>}></Route>
                </Routes>
            </BrowserRouter>
        </SkeletonTheme>
    );
}

export default App;
