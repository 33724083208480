import {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import Swal from "sweetalert2";
import ApiRequest from "../../classes/ApiRequest";

const LoginPage = () => {


    const navigator = useNavigate();

    useEffect(() => {
        const storedToken = localStorage.getItem('token');

        if (storedToken) {
            navigator('/threads');
        } else {
            const apiRequest = new ApiRequest('POST', `${process.env.REACT_APP_API_URL}/api/login`, JSON.stringify(userInfo));
            apiRequest.editHeader("Authorization", `Bearer ${process.env.REACT_APP_API_KEY}`);
            apiRequest.sendRequest()
                .then(result => {
                    const parsed = JSON.parse(result);
                    console.log(parsed);
                    if (parsed.success) {
                        localStorage.setItem('token', parsed.data.token);
                        navigator('/threads');
                    } else {

                    }
                })
                .catch(error => {
                    console.error('Login failed:', error);
                });
        }
    }, []);

    const [userInfo, setUserInfo] = useState({
        username: '',
        password: '',
        device: window.navigator.platform
    })

    const formChangeHandler = evt => {
        const name = evt.target.name
        const value = evt.target.value
        setUserInfo((prevState) => {
            return {...prevState, [name]: value}
        })
    }

    const formSubmitHandler = evt => {
        evt.preventDefault()

        const apiRequest = new ApiRequest('POST', `${process.env.REACT_APP_API_URL}/api/login`, JSON.stringify(userInfo))
        apiRequest.editHeader("Authorization", `Bearer ${process.env.REACT_APP_API_KEY}`)
        apiRequest.sendRequest()
            .then(result => {
                const parsed = JSON.parse(result)
                console.log(parsed)
                if(parsed.success) {
                    localStorage.setItem('token', parsed.data.token)
                    navigator('/threads');
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: parsed.data.error,
                    })
                }
            })
            .catch(error => null);
    }

    return (
        <main className="container-fluid">
            <div className="row flex-md-row-reverse min-vh-100">

                <div className="d-none d-md-block col-12 col-md-6 bg-primary align-items-center">
                    <div className="d-flex flex-column justify-content-center align-items-center h-100">
                        <h1 className={"text-white fw-bold"} style={{fontSize: 38}}>betterEdu</h1>
                        <div className="text-white mt-2" style={{fontSize: '16px'}}>
                            <div className="fw-light">Made with love by <span className="fw-bold">intelecto</span></div>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-6 mt-5 mt-md-0 d-flex justify-content-center align-items-center align-self-md-start align-self-md-center">
                    <div className="container w-495">
                        <form onSubmit={formSubmitHandler}>
                            <h2 className="mb-2 fw-bold">Prijavite se na betterEdu</h2>
                            <h5 className="mb-4">Drago nam je što Vas opet vidimo!</h5>
                            <div>
                                <div className="m-0 mb-3">
                                    <input type="text" placeholder="Username" onChange={formChangeHandler}
                                           value={userInfo.username} name="username"
                                           className="form-control rounded-3 border-0"/>
                                </div>

                                <div className="m-0 mb-3">
                                    <input type="password" placeholder="Šifra" onChange={formChangeHandler}
                                           value={userInfo.password} name="password"
                                           className="form-control rounded-3 border-0 m-0 mb-3"/>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between mb-0 mt-2">
                                <div>
                                    <input id="remember_me" type="checkbox" className="form-check-input"/>
                                    <label htmlFor="remember_me" className="ms-2 fw-medium form-check-label">Zapamti
                                        me</label>
                                </div>
                                <Link to="/reset" className="text-primary fw-medium text-decoration-none mb-3">Zaboravili
                                    ste šifru?</Link>
                            </div>
                            <button type="submit"
                                    className="btn btn-primary w-100 mb-3 fw-bold fs-5 rounded-3 py-2">Prijavi se
                            </button>
                            <h6 className="fw-medium">Nemate nalog?
                                <Link to="/register"
                                      className="text-primary fw-medium text-decoration-none"> Kreirajte ga</Link>
                            </h6>
                        </form>
                    </div>
                </div>

            </div>
        </main>
    )
}

export default LoginPage