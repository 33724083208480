import PropTypes from "prop-types";
import * as Unicons from '@iconscout/react-unicons';

const DateInput = props => {

    return (
        <div className="date bg-secondary-subtle px-2 d-flex justify-content-between align-items-center">
            <div className="date-icon pe-1">
                <Unicons.UilCalender size="20px" className="text-primary-emphasis"/>
            </div>
            <input className="date-input bg-secondary-subtle text-primary-emphasis w-100" type="date" placeholder={props.placeholder}/>
        </div>
    )
}

DateInput.propTypes = {
    placeholder: PropTypes.string
}

export default DateInput