import CategoryButton from "./CategoryButton";
import * as Unicons from '@iconscout/react-unicons';
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import ApiRequest from "../classes/ApiRequest";
import ActivityButton from "./ActivityButton";

const ThreadCard = (p) => {

    const navigator = useNavigate()

    const [likes, setLikes] = useState(0)

    useEffect(() => {
        setLikes(() => p.data.likes)
    }, []);

    const threadDeleteHandler = (e) => {
        e.preventDefault()

        const apiRequest = new ApiRequest('DELETE', `${process.env.REACT_APP_API_URL}/api/forums/${p.data.id}`)
        apiRequest.sendRequest()
            .then(result => {
                console.log(JSON.parse(result))
                navigator(-1)
            })
            .catch(error => console.log('Error@ThreadPage', error))
    }

    return (
        <div className="bg-secondary-subtle col mt-2 py-3 rounded-4 h-100 d-flex flex-column justify-content-between cursor-pointer hover-effect">

            <div className="text-primary-emphasis ms-3 d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <img src={p.data.user.photoPath} className="rounded-circle" height="26" alt=""/>
                    <div className="d-flex flex-column">
                        <div className="ps-2 fw-semibold" style={{fontSize: "12px"}}>{p.data.user.name + ' ' + p.data.user.surname}</div>
                        <div className="ps-2 " style={{fontSize: "12px"}}>{'@'+p.data.user.username}</div>
                    </div>
                </div>

                <div className="d-flex">
                    {p.data.isResolved ? <div className="ms-1">
                        <Unicons.UilCheckCircle color="#0F0" size="15"/>
                    </div> : null}

                    <div className="me-2 d-flex">
                        <div className="dropdown">
                            <div data-bs-toggle="dropdown" aria-expanded="false">
                                <Unicons.UilEllipsisV size="26" className="text-primary-emphasis cursor-pointer"/>
                            </div>
                            <ul className="dropdown-menu z-0">
                                <li>
                                    <div onClick={() => navigator(`/thread/${p.data.id}/edit`)}
                                         className="dropdown-item text-primary-emphasis">Edit</div>
                                </li>
                                <li>
                                    <div onClick={threadDeleteHandler}
                                         className="dropdown-item text-danger">Delete</div>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>

            <div onClick={() => navigator(`/thread/${p.data.id}`)}>
                <div className="px-3 text-primary-emphasis mt-2">
                    <div className="fw-medium" style={{fontSize: "16px"}}>{p.data.title}</div>
                </div>

                {p?.data.media.length !== 0 ?
                    <div className="mx-3 my-2">
                        {
                            p.data.media[0].path && p.data.media[0].path.slice(p.data.media[0].path.length - 3) !== "mp4" ?
                                <img
                                    src={p.data.media[0].path}
                                    alt="Commission Display"
                                    className={"img-fluid rounded-3"}
                                />
                                :
                                <iframe
                                    src={p.data.media[0].path}
                                    allowFullScreen
                                    style={{position: "relative", display: "block", width: "100%", height: "50vh", overflow: "none"}}

                                />

                        }
                    </div> : null}
            </div>

            <div className="d-flex overflow-x-scroll pe-3 mb-1">
                {p.data.categories.map((category, index) =>
                    <div key={category.id}
                         className={index === 0 ? 'ms-3 me-1' : 'mx-1'}>
                        <CategoryButton color={category.color}>{category.name}</CategoryButton>
                    </div>)}
            </div>

            <div className="d-flex justify-content-between align-items-center">
                <div className="ms-3 mt-2 text-primary-emphasis">
                    <div><b>{likes}</b> likes</div>
                </div>
                <div className="d-flex column-gap-3 me-3 text-primary-emphasis">

                    <div className="cursor-pointer" onClick={() => navigator(`/thread/reply/${p.data.id}`)}>
                        <Unicons.UilComment size="30"></Unicons.UilComment>
                    </div>
                    <Unicons.UilShare size="30" />
                    <ActivityButton id={p.data.id} type="like" contentType="forums" setLikes={setLikes}
                                    isActive={p.data.isLiked}/>

                </div>
            </div>
        </div>
    )
}

export default ThreadCard