import * as Unicons from "@iconscout/react-unicons";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import BottomNavBar from "../../components/BottomNavBar";
import ApiRequest from "../../classes/ApiRequest";
import Swal from "sweetalert2";

const SettingsScreen = () => {

    const navigator = useNavigate();

    const [isLightTheme, setIsLightTheme] = useState(false);

    const toggleTheme = () => {
        setIsLightTheme(!isLightTheme);
    }

    const formSubmitHandler = evt => {
        evt.preventDefault()

        const apiRequest = new ApiRequest('POST', `${process.env.REACT_APP_API_URL}/api/logout`)
        apiRequest.sendRequest()
            .then(result => {
                localStorage.removeItem('token')
                navigator('/login');
            })
            .catch(error => null);
    }

    const valueText = isLightTheme ? "Light theme" : "Dark theme";

    const toggleIcon = isLightTheme ? (<img src="/assets/dummy_pictures/toggleOn.svg" className="m-0" alt=""/>) : (
        <img src="/assets/dummy_pictures/toggleOff.svg" className="m-0" alt=""/>);

    return (
       <BottomNavBar>
           <div className="container-fluid bg-body min-vh-100" data-bs-theme={localStorage.getItem("theme")}>
               <div className="row border-bottom border-secondary-subtle py-1 mb-2">
                   <div className="col-9 d-flex align-items-center p-0">
                       <div onClick={() => navigator(-1)}>
                           <Unicons.UilArrowLeft size="40" className="ms-1 text-primary-emphasis cursor-pointer"/>
                       </div>
                       <div className="ms-1 fw-semibold text-primary-emphasis"
                            style={{fontSize: '16px'}}>Settings</div>
                   </div>
               </div>

               <div>
                   <form>
                       <label htmlFor='appearance' className='text-secondary mt-3 mb-2'>Izgled</label>
                       <div className="toggle-bar bg-secondary-subtle px-2  d-flex justify-content-between align-items-center">
                           <input className="toggle-input bg-secondary-subtle text-primary-emphasis w-100" readOnly type="text" value={valueText} />
                           <div className="pe-1 m-0">
                               <div onClick={toggleTheme}>{toggleIcon}</div>
                           </div>
                       </div>
                   </form>
               </div>

               <div>
                   <label htmlFor='appearance' className='text-secondary mt-3 mb-2'>Profil</label>
                   <div className="toggle-bar bg-secondary-subtle px-2  mb-2 d-flex justify-content-between align-items-center">
                       <a className={"bg-secondary-subtle text-primary-emphasis w-100 text-decoration-none"} href="">Sačuvane objave</a>
                   </div>
                   <div  className="toggle-bar bg-secondary-subtle px-2 d-flex justify-content-between align-items-center cursor-pointer">
                       <a className={"bg-secondary-subtle text-danger w-100 text-decoration-none"} onClick={formSubmitHandler}>Odjavi se</a>
                   </div>
               </div>

           </div>
       </BottomNavBar>
    );
}

export default SettingsScreen;