import ApiRequest from "../classes/ApiRequest";
import * as Unicons from "@iconscout/react-unicons";
import React, {useState} from "react";




const ActivityButton = (p) => {

    const [toggle, setToggle] = useState(p.isActive)

    const toggleHandler = () => {
        if (toggle) {
            setToggle(false)
            if (p.setLikes)
                p.setLikes(prevState => prevState - 1)
        }
        else {
            setToggle(true)
            if(p.setLikes)
                p.setLikes(prevState => prevState + 1)
        }
    }

    const activityHandler = (e) => {
        e.preventDefault()
        const apiRequest = new ApiRequest('POST', `${process.env.REACT_APP_API_URL}/api/${p.contentType}/${p.id}/activity`, JSON.stringify({"type": p.type}))
        apiRequest.sendRequest()
            .then(result => toggleHandler())
            .catch(error => console.log('Error@ThreadPage', error))
    }

    return (
        <div onClick={activityHandler} className="cursor-pointer">
            {
                p.type === 'like' ? <Unicons.UilHeartAlt size={p.size ? p.size : "30"} color={toggle ? '#F00' : '#FFF'}/> :
                p.type === 'save' ? <Unicons.UilBookmark size={p.size ? p.size : "30"} color={toggle ? '#FF0' : '#FFF'} /> :
                console.error(`Invalid ActivityButton type, got ${p.type}, chose between like and save.`)
            }
        </div>
    )
}

export default ActivityButton