import React from "react";
import UilArrowLeft from "@iconscout/react-unicons/icons/uil-arrow-left";
import {useNavigate} from "react-router-dom";


import BottomNavBar from "../components/BottomNavBar";

const NotFound = (props) => {
    const navigator = useNavigate();

    return (
        <BottomNavBar>
            <div className="container-fluid bg-body min-vh-100 d-flex flex-column"
                 data-bs-theme={localStorage.getItem('theme')}>
                <div className="row">
                    <div className="col d-flex justify-content-start p-0">
                        <div onClick={() => navigator(-1)}>
                            <UilArrowLeft size="50" className="text-primary-emphasis"/>
                        </div>
                    </div>
                </div>
                <div className="flex-grow-1 d-flex justify-content-center align-items-center">
                    <div className="text-center text-primary-emphasis" style={{fontSize: '18px'}}>
                        Stranica nije dostupna
                    </div>
                </div>
            </div>
        </BottomNavBar>
    );
}

export default NotFound;
