import React from "react";
import {mouseLeaveHandler, mouseMoveHandler} from "../classes/CardScript";

const Card = (props) => {

    return(
        // <div className="coolcard-wrapper flex-grow-1 my-2 mx-2 mx-md-2" style={{ borderColor: "rgba(53, 53, 53, 1)", borderRadius: "13px", backgroundColor: "transparent"}}>
        //     <div onMouseMove={mouseMoveHandler} onMouseLeave={mouseLeaveHandler} className="coolcard w-100 h-100">
        //         <div className="coolcard-blob"></div>
        //         <div className="coolcard-blur w-100 h-100 backdrop-filter"></div>
        //         <div className="coolcard-content w-100 h-100 ">
        //             <div className="card-group px-3 pt-3" style={{ paddingBottom: props.padding }}>
        //                 <h2 className="fw-bold text-white" style={{ fontSize: "24px" }}>{props.title}</h2>
        //                 <p className="text-start text-white" style={{ fontSize: "16px"}} dangerouslySetInnerHTML={{ __html: props.description }}></p>
        //             </div>
        //         </div>
        //     </div>
        //
        // </div>

        <div className={'card container py-4'} style={{background: "#181818"}}>
            <h5 className=" fw-bold text-white" style={{ fontSize: "24px" }}>{props.title}</h5>
            <div style={{color: "#D9D9D9"}} dangerouslySetInnerHTML={{ __html: props.description }}>
            </div>
        </div>

    // <div className="coolcard-wrapper card flex-grow-1 my-2 mx-2 mx-md-2" style={{ borderColor: "rgba(53, 53, 53, 1)", borderRadius: "13px", backgroundColor: "transparent"}}>
    //     <div className="coolcard-wrapper">
    //         <div className="coolcard w-100 h-100">
    //             <div className="coolcard-blob"></div>
    //             <div className="coolcard-blur w-100 h-100 backdrop-filter"></div>
    //             <div className="coolcard-content w-100 h-100">
    //                 <div className="row m-0 w-100 h-100 justify-content-center">
    //                     <div className="w-100 h-55 backdrop-filter card-gray p-0">
    //                         <img src="https://e24joaz2t6m.exactdn.com/wp-content/uploads/2016/06/xpbliss.jpg" alt=""
    //                              className="w-100 h-100"/>
    //                     </div>
    //                     <div className="row p-4">
    //                         <h1 className="p-0">Bliss</h1>
    //                         <p className="p-0 mt-2">This masterpiece is a very good looking wallpaper that was used in
    //                             Windows XP.</p>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>


    );
}

export default Card;