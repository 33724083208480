import React, {useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import ApiRequest from "../classes/ApiRequest";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const NavBar = () => {

    const navigator = useNavigate()
    const location = useLocation()
    const path = location.pathname

    const [user, setUser] = useState(null)

    useEffect(() => {
        const userDataRequest = new ApiRequest('GET', `${process.env.REACT_APP_API_URL}/api/user`);
        userDataRequest.sendRequest()
            .then(result => setUser(() => JSON.parse(result).data))
            .catch(error => console.log('Error@ProfilePage', error));
    }, []);

    return (
        <nav className="navbar navbar-expand-lg" style={{ minHeight: 40, backgroundColor: 'rgba(247, 247, 247, 1)'}}>
            <div className="container">
                <a className="navbar-brand fw-bold" href="#">betterEdu</a>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav m-md-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            {path === '/' ?
                            <a className="nav-link active fw-bold" aria-current="page" href="/">Početna</a> :
                            <a className="nav-link" href="/">Početna</a> }
                        </li>
                        <li className="nav-item">
                            {path === '/readmore' ?
                            <a className="nav-link active fw-bold" aria-current="page"  href="/readmore">Saznaj više</a>:
                            <a className="nav-link" href="/readmore">Saznaj više</a>}
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">API</a>
                        </li>
                    </ul>
                </div>
                <div className="d-flex ms-auto pe-2 pe-lg-0">
                    {
                        localStorage.getItem('token')
                            ?
                            <a href={'/threads'} className="text-primary-emphasis text-center text-decoration-none cursor-pointer">
                                <div>
                                    <p className={'fw-bold mb-0'}><img className="rounded-circle me-1" src={user?.photoPath} alt="" style={{width: '30px'}}/> {user?.name}</p>
                                </div>
                            </a>


                            : <button className='btn btn-dark' type='button' onClick={() => navigator('login')}>Pridruži se</button>

                    }
                </div>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
            </div>
        </nav>
    );
}
export default NavBar;