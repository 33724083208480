import AWS from "aws-sdk";
import ApiRequest from "./ApiRequest";

/**
 * A class for making API requests with customizable headers.
 */
export default class S3Uploader {

    /**
     * Create a new instance of S3Uploader.
     * @param {File} file - File to upload.
     */
    constructor(file) {
        AWS.config.update({
            accessKeyId: process.env.REACT_APP_S3_ACCESS,
            secretAccessKey: process.env.REACT_APP_S3_SECRET,
        });

        this.s3 = new AWS.S3({
            params: {Bucket: process.env.REACT_APP_S3_BUCKET},
            region: process.env.REACT_APP_S3_BUCKET_REGION
        })

        this.params = {
            Bucket: process.env.REACT_APP_S3_BUCKET,
            Key: `${crypto.randomUUID()}${new Date().getTime()}${file.name}`,
            Body: file,
            ContentType: file.type
        };
    }

    upload() {

        return this.s3.upload(this.params).promise()
            // if (err) {
            //     console.error('Error uploading file:', err);
            // } else {
            //     console.log('File uploaded successfully:', data);
            //
            //
            // }

    }
}
