import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Unicons from "@iconscout/react-unicons";
import ApiRequest from "../../classes/ApiRequest";
import "cropperjs/dist/cropper.css";
import Cropper from "react-cropper";
import S3Uploader from "../../classes/S3Uploader";
import BottomNavBar from "../../components/BottomNavBar";

const EditProfilePage = () => {

    const navigator = useNavigate();

    const [base64CroppedImage ,setBase64CroppedImage] = useState()
    const [file, setFile] = useState(null);
    const [profile, setProfile] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [cropper, setCropper] = useState(null);
    const [showCropper, setShowCropper] = useState(false);

    useEffect(() => {
        const apiRequest = new ApiRequest("GET", `${process.env.REACT_APP_API_URL}/api/user`);
        apiRequest
            .sendRequest()
            .then((result) => setProfile(() => JSON.parse(result).data))
            .catch((error) => console.log("Error@EditProfilePage", JSON.parse(error)));
    }, []);

    const formSubmitHandler = () => {

        const raw = {
            "username": profile?.username,
            "name": profile?.name,
            "surname": profile?.surname,
            "bio": profile?.bio
        }

        if (croppedImage) {

            new S3Uploader(croppedImage).upload()
                .then(data => {
                    console.log('Successfully uploaded:', data.Location);
                    raw.photoPath = data.Location
                    const apiRequest = new ApiRequest('PATCH', `${process.env.REACT_APP_API_URL}/api/user/update`, JSON.stringify(raw));
                    apiRequest.sendRequest()
                        .then(result => {
                            navigator(-1)
                            console.log(JSON.parse(result))
                        })
                        // .then(result => console.log(JSON.parse(result)))
                        .catch(error => console.log('Error@EditProfilePage', error));
                })
                .catch(err => {
                    console.error('Error uploading:', err);
                });

        } else {
            const apiRequest = new ApiRequest('PATCH', `${process.env.REACT_APP_API_URL}/api/user/update`, JSON.stringify(raw));
            apiRequest.sendRequest()
                .then(result => {navigator(-1)})
                .catch(error => console.log('Error@EditProfilePage', error));
        }
    }

    const formChangeHandler = (evt) => {
        const name = evt.target.name;
        const value = evt.target.value;
        setProfile((prevState) => {
            return { ...prevState, [name]: value.trimStart() };
        });
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);

            setTimeout(() => {
                setShowCropper(true);
            }, 200);
        }
    };

    const cropImage = () => {
        if (file && cropper) {
            const croppedCanvas = cropper.getCroppedCanvas();

            croppedCanvas.toBlob((blob) => {
                if (!blob) {
                    console.error("Could not create a blob from the cropped canvas.");
                    return;
                }
                const croppedImageFile = new File([blob], "cropped-image.png", { type: "image/png" });

                setCroppedImage(croppedImageFile);

                const croppedDataUrl = croppedCanvas.toDataURL();
                setBase64CroppedImage(croppedDataUrl);

                setShowCropper(false);
            }, "image/png");
        }
    };

    useEffect(() => {
        // Automatically crop the image when the file changes
        if (file) {
            cropImage();
        }
    }, [file]);


    return (
        <BottomNavBar>
            <div className="container-fluid bg-body min-vh-100" data-bs-theme={localStorage.getItem("theme")}>
            <div className="row">
                <div className="col d-flex align-items-center p-0">
                    <div onClick={() => navigator(-1)}>
                        <Unicons.UilArrowLeft size="40" className="ms-1 text-primary-emphasis" />
                    </div>
                    <div className="ms-2 text-primary-emphasis fw-bold" style={{ fontSize: "16px" }}>
                        Izmijeni profil
                    </div>
                </div>

                <div className="col justify-content-end align-items-center d-flex p-2 m-0">
                    <button
                        onClick={formSubmitHandler}
                        className="btn btn-primary">Sačuvaj</button>
                </div>
            </div>
            <div className="pt-5 pb-4">
                <div className="d-flex align-items-center justify-content-center flex-column p-0">
                    <div className="d-flex align-items-center justify-content-center p-0">
                        <label htmlFor="upload">
                            <img
                                src={base64CroppedImage || profile?.photoPath}
                                id="profileImage"
                                alt=""
                                className="rounded-circle"
                                style={{ width: "80px" }}
                            />
                        </label>
                    </div>
                    <label htmlFor="upload" className="text-primary mt-2">
                        Tapnite za izmjenu
                    </label>
                    <input id="upload" name="upload" type="file" style={{ display: "none" }} onChange={handleFileChange} />
                </div>
            </div>
            <div>
                <form>
                    <div>
                        <div className="m-0 mb-3">
                            <label htmlFor="name" className="text-secondary mb-2 text-primary-emphasis">
                                Ime
                            </label>
                            <input
                                type="text"
                                placeholder="Name"
                                defaultValue={profile?.name}
                                id="name"
                                name="name"
                                onChange={formChangeHandler}
                                className="text-primary-emphasis form-control placeholder-gray rounded-3 border-0 m-0 bg-secondary-subtle"
                            />
                        </div>
                        <div className="m-0 mb-3">
                            <label htmlFor="surname" className="text-primary-emphasis text-secondary mb-2">
                                Prezime
                            </label>
                            <input
                                type="text"
                                placeholder="Surname"
                                defaultValue={profile?.surname}
                                id="surname"
                                name="surname"
                                onChange={formChangeHandler}
                                className="text-primary-emphasis form-control placeholder-gray rounded-3 border-0 m-0 bg-secondary-subtle"
                            />
                        </div>
                        <div className="m-0 mb-3">
                            <label htmlFor="username" className="text-primary-emphasis text-secondary mb-2">
                                Korisničko ime
                            </label>
                            <input
                                type="text"
                                placeholder="Username"
                                defaultValue={profile?.username}
                                id="username"
                                name="username"
                                onChange={formChangeHandler}
                                className="text-primary-emphasis form-control placeholder-gray rounded-3 border-0 m-0 bg-secondary-subtle"
                            />
                        </div>
                        <div className="m-0 mb-3">
                            <label htmlFor="bio" className="text-primary-emphasis text-secondary mb-2">
                                Bio
                            </label>
                            <textarea
                                name="bio"
                                rows="5"
                                id="bio"
                                className="text-primary-emphasis form-control placeholder-gray form-control-lg rounded-3 border-0 m-0 bg-secondary-subtle"
                                placeholder="Bio"
                                onChange={formChangeHandler}
                                defaultValue={profile?.bio}
                                style={{ resize: "none" }}
                            ></textarea>
                        </div>
                    </div>
                </form>
            </div>
            {/* Image Cropper */}
            {file && showCropper && (
                <div className="position-fixed top-0 start-0 h-100 d-flex justify-content-center align-items-center bg-overlay mx-3">
                    <div className="p-3 shadow bg-body-tertiary border-3 border-secondary  rounded-2">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title text-primary-emphasis">Crop Image</h5>
                            </div>
                            <div className="modal-body my-3">
                                <Cropper
                                    src={file ? URL.createObjectURL(file) : ""}
                                    aspectRatio={1}
                                    guides={true}
                                    viewMode={1}
                                    initialAspectRatio={1}
                                    autoCropArea={1}
                                    background={false}
                                    responsive={true}
                                    checkCrossOrigin={false}
                                    onInitialized={(instance) => setCropper(() => instance)}
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={cropImage}>
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>

        </BottomNavBar>
    );
};

export default EditProfilePage;
