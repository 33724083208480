import PropTypes from "prop-types";
import * as Unicons from '@iconscout/react-unicons';

const SearchBar = props => {

    const [input, setInput] = props.inputState


    const handleInputChange = e => {
        setInput(() => e.target.value)
    }

    return (
        <div className="search-bar bg-secondary-subtle px-2 d-flex justify-content-between align-items-center">
            <div className="search-icon pe-1">
                <Unicons.UilSearch size="20px" className="text-primary-emphasis"/>
            </div>
            <input className="search-input bg-secondary-subtle text-primary-emphasis w-100" type="text" placeholder={props.placeholder} onChange={handleInputChange}/>
        </div>
    )
}

SearchBar.propTypes = {
    placeholder: PropTypes.string
}

export default SearchBar