import {useNavigate, useParams} from "react-router-dom";
import * as Unicons from "@iconscout/react-unicons";
import React, {useEffect, useState} from "react";
import CategoryButton from "../../components/CategoryButton";
import UilHeart from "@iconscout/react-unicons/icons/uil-heart";
import ApiRequest from "../../classes/ApiRequest";

import BottomNavBar from "../../components/BottomNavBar";

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const ResourcePage = () => {

    const params = useParams()
    const navigator = useNavigate()

    const [resource, setResource] = useState(null)

    useEffect(() => {

        const apiRequest = new ApiRequest('GET', `${process.env.REACT_APP_API_URL}/api/resources/${params.id}`)
        apiRequest.sendRequest()
            .then(result => (setResource(() => JSON.parse(result))))
            .catch(error => console.log('Error@ResourcePage', error))
    }, []);

    const resourceDeleteHandler = (e) => {
        e.preventDefault()

        const apiRequest = new ApiRequest('DELETE', `${process.env.REACT_APP_API_URL}/api/resources/${params.id}`)
        apiRequest.sendRequest()
            .then(result => {
                console.log(JSON.parse(result))
                navigator(-1)
            })
            .catch(error => console.log('Error@ResourcePage', error))
    }

    const getDateTime = () => {

        const diff = resource?.data.created_at[0]
        const date = resource?.data.created_at[1]

        if (diff?.split(0)[0] <= 7 && diff?.split(0)[1] === 'day') {
            return diff
        }

        return date
    }


    return (
        <BottomNavBar>
            <div className="container-fluid bg-body min-vh-100"
                 data-bs-theme={localStorage.getItem('theme')}>

                <div className="row border-bottom border-secondary-subtle py-1">
                    <div className="col-9 d-flex align-items-center p-0">
                        <div onClick={() => navigator(-1)}>
                            <Unicons.UilArrowLeft size="40" className="ms-1 text-primary-emphasis cursor-pointer"/>
                        </div>
                        <div className="ms-2 fw-semibold text-primary-emphasis text-nowrap"
                             style={{fontSize: '16px'}}>{resource?.data.title || <Skeleton/>}</div>
                    </div>
                    <div className="col-3 justify-content-end align-items-center d-flex p-2 m-0">
                        <div className="dropdown">
                            <div data-bs-toggle="dropdown" aria-expanded="false">
                                <Unicons.UilEllipsisV size="26" className="text-primary-emphasis cursor-pointer"/>
                            </div>
                            <ul className="dropdown-menu">
                                <li>
                                    <div onClick={() => navigator(`/resource/${params.id}/edit`)}
                                         className="dropdown-item text-primary-emphasis">Edit</div>
                                </li>
                                <li>
                                    <div onClick={resourceDeleteHandler}
                                         className="dropdown-item text-danger">Delete</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="d-flex overflow-x-scroll mt-3 px-2">
                    {resource?.data.categories.map((category, index) =>
                        <div key={index}
                             className={index === 0 ? ' me-1' : 'mx-1'}>
                            <CategoryButton>{category.name}</CategoryButton>
                        </div>)}
                </div>

                <div className="d-flex flex-column row-gap-3 mt-3 px-2">
                    <div className="text-primary-emphasis fs-2 fw-semibold">{resource?.data.title || <Skeleton/>}</div>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center column-gap-2">
                            <img src={resource?.data.user.photoPath || <Skeleton/>} alt="" width="25" className="rounded-circle "/>
                            <div className="text-primary fw-medium"
                                 style={{fontSize: '14px'}}>{resource?.data.user.name} {resource?.data.user.surname || <Skeleton/>}</div>
                        </div>
                        <div className="text-primary-emphasis fw-semibold"
                             style={{fontSize: '14px'}}>{getDateTime() || <Skeleton/>}</div>
                    </div>
                    <div className="text-primary-emphasis fw-medium"
                         style={{fontSize: '14px'}}>{resource?.data.description || <Skeleton/>}</div>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="text-primary-emphasis" style={{fontSize: '14px'}}>
                            <b>{resource?.data.likes}</b> zahvalnica
                        </div>
                        <div
                            className="d-flex align-items-center text-primary-emphasis border rounded-2 px-2 py-1 bg-secondary-subtle"
                            style={{fontSize: '14px'}}>
                            <div className="me-2 fw-semibold">Zahvali se</div>
                            <UilHeart size="20"/>
                        </div>
                    </div>
                    <div className="d-flex mt-2 column-gap-4 justify-content-left text-primary-emphasis">
                        <img height="150" className="rounded-2 border"
                             src={resource?.data.media[0].path}
                             alt=""/>
                        <div className="d-flex flex-column justify-content-between py-2">
                            <div className="d-flex flex-column row-gap-2">
                                <div className="fw-semibold" style={{fontSize: '16px'}}>{resource?.data.title || <Skeleton/>}</div>
                                <div style={{fontSize: '12px'}}>164KB</div>
                            </div>
                            <div
                                className="d-flex fw-semibold align-items-center justify-content-center border rounded-2 px-2 py-1 bg-secondary-subtle"
                                style={{fontSize: '14px'}}>
                                <a href={resource?.data.media[0].path || <Skeleton/>}>Preuzmi</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BottomNavBar>
    );
}

export default ResourcePage
