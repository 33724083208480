import React, {useEffect, useState} from "react";
import SearchBar from "./SearchBar";
import CategoryButton from "./CategoryButton";
import ApiRequest from "../classes/ApiRequest";

const ResourceTagPicker = p => {

    const [categories, setCategories] = useState(null);

    const [tags, setTags] = p.tagState;

    const searchState = useState(false)
    const [searchInput, setSearchInput] = searchState

    useEffect(() => {

        const apiRequest = new ApiRequest('GET', `${process.env.REACT_APP_API_URL}/api/forums/create`)
        apiRequest.sendRequest()
            .then(result => setCategories(JSON.parse(result)))
        // .catch(error => console.log('error', error));
    }, []);



    return (
        <div
            className="position-fixed top-0 left-0 w-100 h-100 d-flex align-items-center justify-content-center overlay"
            data-bs-theme="dark">
            <div style={{maxWidth: '500px'}} className="bg-body-tertiary m-3 p-4 rounded shadow">
                {categories ?
                    <>
                        <SearchBar placeholder="Search..." inputState={searchState}/>

                        <div className="mt-3 d-flex flex-wrap column-gap-2 row-gap-2">
                            {categories.data.map(category => {
                                return <CategoryButton key={category.id}
                                                       id={category.id} onClick={p.tagChangeHandler}>{category.name}</CategoryButton>
                            })}
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <button type="button"
                                    style={{textDecoration: 'none'}}
                                    className="btn btn-link text-primary-emphasis mt-3"
                                    onClick={() => setTags([])}>Reset</button>

                            <button type="button"
                                    className="btn btn-secondary mt-3 text-primary-emphasis"
                                    onClick={() => p.setTagPickerVisible(false)}>Close</button>
                        </div>
                    </>
                    :
                    <div>Loading...</div>
                }
            </div>
        </div>
    )
}

export default ResourceTagPicker