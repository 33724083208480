import React from "react";

const TeamMemberCard = (props) => {
    return (
        <div className="col">
            <div className="card d-flex rounded-4 mb-4" style={{ backgroundColor: 'rgba(24, 24, 24, 1)', borderColor: 'rgba(53, 53, 53, 1)' }}>
                <div className="d-flex justify-content-center align-items-center pt-3 rounded-circle">
                    <img src={props.img} className='img-fluid' alt="" style={{ objectFit: "contain" ,border: "1px #353535 solid", borderRadius: "100%", maxHeight: '80px', maxWidth: '80px' }} />
                </div>
                <div className="card-body text-white text-center pb-2">
                    <p className="card-title" style={{ }}>{props.name}</p>
                </div>
            </div>
        </div>
    );
}

export default TeamMemberCard;