import * as Unicons from '@iconscout/react-unicons';
import ThreadComment from "../../components/ThreadComment";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import ApiRequest from "../../classes/ApiRequest";

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import CategoryButton from "../../components/CategoryButton";
import ActivityButton from "../../components/ActivityButton";
import BottomNavBar from "../../components/BottomNavBar";
import {UilCopy} from "@iconscout/react-unicons";

const ForumPage = (props) => {

    const params = useParams()
    const navigator = useNavigate()

    const [thread, setThread] = useState(null)
    const [likes, setLikes] = useState(0)

    useEffect(() => {
        const apiRequest = new ApiRequest('GET', `${process.env.REACT_APP_API_URL}/api/forums/${params.id}`)
        apiRequest.sendRequest()
            .then(result => {
                setThread(() => JSON.parse(result).data)
                setLikes(() => JSON.parse(result).data.likes)
            })
            .catch(error => console.log('Error@ForumPage', error))
    }, [params.id]);

    console.log(thread)

    const threadDeleteHandler = (e) => {
        e.preventDefault()

        const apiRequest = new ApiRequest('DELETE', `${process.env.REACT_APP_API_URL}/api/forums/${params.id}`)
        apiRequest.sendRequest()
            .then(result => {
                console.log(JSON.parse(result))
                navigator(-1)
            })
            .catch(error => console.log('Error@ThreadPage', error))
    }

    const getDateTime = () => {

        const diff = thread?.created_at[0]
        const date = thread?.created_at[1]

        if (diff?.split(0)[0] <= 7 && diff?.split(0)[1] === 'day') {
            return diff
        }

        return date
    }

    return (
        <BottomNavBar>
            <div className="container-fluid bg-body min-vh-100" data-bs-theme={localStorage.getItem('theme')}>

                <div className="row border-bottom border-secondary-subtle">
                    <div className="d-flex justify-content-between align-items-center p-0 pe-2">
                        <div className="col d-flex align-items-center p-0">
                            <div onClick={() => navigator(-1)} className="cursor-pointer">
                                <Unicons.UilArrowLeft size="40" className="ms-1 text-primary-emphasis"/>
                            </div>
                            <div className="ms-2 text-primary-emphasis"  style={{fontSize: '16px'}}>Detalji</div>
                        </div>
                        <div className="dropdown">
                            <div data-bs-toggle="dropdown" aria-expanded="false">
                                <Unicons.UilEllipsisV size="26" className="text-primary-emphasis cursor-pointer"/>
                            </div>
                            <ul className="dropdown-menu">
                                <li>
                                    <div onClick={() => navigator(`/thread/${params.id}/edit`)}
                                         className="dropdown-item text-primary-emphasis">Edit</div>
                                </li>
                                <li>
                                    <div onClick={threadDeleteHandler}
                                         className="dropdown-item text-danger">Delete</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="mt-2 fw-bold text-primary-emphasis" style={{fontSize: "18px"}}>{thread?.title || <Skeleton count={1}/>}</div>

                <div className="d-flex overflow-x-scroll mt-3 mb-2">
                    {thread?.categories.map((category, index) =>
                        <div key={index}
                             className={index === 0 ? ' me-1' : 'mx-1'}>
                            <CategoryButton>{category.name}</CategoryButton>
                        </div>)}
                </div>

                <div className="d-flex mb-2">
                    <div className="d-flex">
                        <div className="justify-content-end align-items-center d-flex pe-2 py-2 m-0">
                            <img className="rounded-circle" src={thread?.user.photoPath || <Skeleton/>} alt="" style={{height: '40px'}}/>
                        </div>

                        <div className="d-flex flex-column justify-content-center">
                            <div className="fw-medium text-primary-emphasis" style={{fontSize: "14px"}}>{thread?.user.name || <Skeleton/>} {thread?.user.surname || <Skeleton/>}</div>
                            <div className="text-primary-emphasis" style={{fontSize: "12px"}}>{"@"+thread?.user.username || <Skeleton/>}</div>
                        </div>
                    </div>
                </div>

                <div className="my-2 fw-medium p-0 text-primary-emphasis" style={{fontSize: "16px"}}>{thread?.description || <Skeleton count={5}/>}</div>

                {thread?.media.length > 0 ?
                    <div className="mb-3">
                        {
                            thread?.media[0].path && thread?.media[0].path.slice(thread?.media[0].path.length - 3) !== "mp4" ?
                                <img
                                    src={thread?.media[0].path}
                                    alt="Commission Display"
                                    className={"img-fluid rounded-3"}
                                />
                                :
                                <iframe
                                    src={thread?.media[0].path}
                                    allowFullScreen
                                    style={{position: "relative", display: "block", width: "100%", height: "50vh", overflow: "none"}}

                                />

                        }
                    </div> : null
                }

                <div className="text-primary my-2" style={{fontSize: "14px"}}>{getDateTime()}</div>

                <div className="d-flex justify-content-between align-items-center py-2">
                    <div className="mt-2 text-primary-emphasis">
                        <div><b>{likes}</b> likes</div>
                    </div>
                    <div className="d-flex column-gap-3  text-primary-emphasis">

                        <div className="cursor-pointer" onClick={() => navigator(`/thread/reply/${params.id}`)}>
                            <Unicons.UilComment size="30"></Unicons.UilComment>
                        </div>
                        <ActivityButton id={params?.id} type="save" contentType="forums" isActive={thread?.isSaved}/>

                        <Unicons.UilShare size="30" />

                        <ActivityButton id={params?.id} type="like" contentType="forums" setLikes={setLikes}
                                        isActive={thread?.isLiked}/>

                        {/*TODO: Share napravi nesto nmp*/}
                        {/*<div className="dropdown">*/}
                        {/*    <div data-bs-toggle="dropdown" className="cursor-pointer" aria-expanded="false">*/}
                        {/*    </div>*/}
                        {/*    <div className="dropdown-menu" style={{width: '75vw'}}>*/}
                        {/*        <div className="container">*/}
                        {/*            <div className="d-flex justify-content-between">*/}
                        {/*                <a href='https://lol.com' target="_blank" rel="noreferrer">*/}
                        {/*                    https://lol.com*/}
                        {/*                </a>*/}
                        {/*                <div id="copyButton" className="">*/}
                        {/*                    <UilCopy></UilCopy>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div className="row pb-5">

                    {thread?.replies?.length > 0 ?
                        thread.replies.map( reply => (
                            <ThreadComment key={reply.id} reply={reply} />
                        )) : console.log("nema")
                    }
                </div>
            </div>
        </BottomNavBar>
    );
}

export default ForumPage;
