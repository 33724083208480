import NavBar from "../components/NavBar";
import Card from "../components/Card";
import Footer from "../components/Footer";
import {useNavigate} from "react-router-dom";
import {mouseLeaveHandler, mouseMoveHandler} from "../classes/CardScript";
import TeamMemberCard from "../components/TeamMemberCard";
import React from "react";

const LandingPage = () => {

    const navigator = useNavigate()

    return (
        <div className='overflow-x-hidden bg-light user-select-none'>
            <div className="d-flex flex-column min-vh-100">
                <NavBar/>
                <section className='py-5 flex-grow-1 d-flex align-items-center'
                         style={{backgroundColor: 'rgba(247, 247, 247, 1)'}}>
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="d-flex col-12 col-lg-5 align-items-center text-center text-lg-start">
                                <div>
                                    <h1 className="fw-bold text-dark display-3">Učenje postaje zabava!</h1>
                                    <p className="py-3 fs-5 text-secondary">Naš cilj je da olakšamo posao profesorima i
                                        učenicima u školama, te da stvorimo bolje uslove za učenje i razvoj. Kvalitetna
                                        edukacija je ključna za budućnost našeg društva, te se zalažemo za stvaranje
                                        sistema koji će biti pravedniji i učinkovitiji za sve.</p>
                                    <div className="buttons">
                                        <button type="button" className="btn btn-outline-dark btn-lg mb-5"
                                                onClick={() => navigator('/')}>Saznaj više
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 text-md-end">
                                <img className="img-fluid" src="/assets/LandingPage/peoples.png" alt=""/>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <section style={{backgroundColor: 'rgba(247, 247, 247, 1)'}} className='py-5'>
                <div className="container">
                    <div className="text-center text-md-start">
                        <h1 className="pt-4 fw-bold pb-2 text-dark display-3">Bolja edukacija, ali kako?</h1>
                    </div>
                    <div className="row">
                        <div className="d-flex justify-content-between p-0 ">
                            <div className="row pb-4">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4 mb-3">
                                    <Card
                                        title="Resursi"
                                        description="BetterEdu omogućava personalizovano učenje, gdje se materijali i lekcije prilagođavaju individualnim potrebama svakog učenika."
                                        padding="0px"
                                    />
                                </div>
                                <div className="col-12 col-lg-4 mb-3">
                                    <Card
                                        title="Forum"
                                        description="Neophodna ti je pomoć? Forum ti omogućava da na jednostavan način potražiš pomoć i diskutuješ o raznim temama."
                                        padding="24px"
                                    />
                                </div>
                                <div className="col-12 col-lg-4">
                                    <Card
                                        title="Panel"
                                        description="Roditeljima je omogućeno da na lakši način imaju učešća u donošenju odluka sa ciljem da edukaciju učine boljom."
                                        padding="24px"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section style={{backgroundColor: 'rgba(247, 247, 247, 1)'}} className='py-5'>
                <div className="container">
                    <div className="row justify-content-between pb-4 pt-5">
                        <div className="col-12 col-lg-6 pe-lg-5">
                            <img className="img-fluid w-100" src="/assets/LandingPage/report.png" alt=""/>
                        </div>
                        <div className="d-flex col-12 col-lg-6 align-items-center text-center text-lg-start ps-lg-5">
                            <div>
                                <h1 className="fw-bold text-dark display-4 pt-4 pt-lg-0">Anonimne prijave</h1>
                                <p className="py-3 fs-5 text-secondary">Nasilje predstavlja ozbiljan problem, kako u
                                    svijetu tako i u Crnoj Gori. Bez obzira na oblik, štetno je za pojedince i društvo.
                                    Suočavanje s nasiljem zahtijeva mjere prevencije, suzbijanja i podrške žrtvama. </p>

                                <div className={'card container py-4'} style={{background: "#181818"}}>
                                    <div style={{color: "#D9D9D9"}}>
                                        BetterEdu omogućava korisnicima da po izboru prijave podnesu anonimno i tako zaštite svoj identitet.
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className='py-5'>
                <div className="container">
                    <div className="row justify-content-between pb-5 pt-3">
                        <div className="d-flex col-12 col-lg-6 align-items-center text-center text-lg-start">
                            <div className='pe-lg-5'>
                                <h1 className="fw-bold text-dark display-4 pt-4 pt-lg-0">Aktivnost je bitna!</h1>
                                <p className="py-3 fs-5 text-secondary">Ograničen broj aktivnosti ili nedostatak
                                    raznovrsnih ponuda može dovesti do toga da se đaci osećaju nedovoljno izazvanim i
                                    motivisanim. Kada im nedostaje prilika da istraže različite oblasti van učionice,
                                    njihova znatiželja može ostati nezadovoljena.</p>

                                <div className={'card container py-4'} style={{background: "#181818"}}>
                                    <div style={{color: "#D9D9D9"}}>
                                        Korisnik ima mogućnost da se zahvali drugom korisniku ukoliko mu je pružio odgovor koji mu je bio od pomoći.
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-12 col-lg-6 text-end ps-lg-5 order-first order-lg-last">
                            <img className="img-fluid w-100" src="/assets/LandingPage/activity.png" alt=""/>
                        </div>
                    </div>
                </div>
            </section>

            <section style={{backgroundColor: '#111111'}} className='pt-5'>
                <div className="container">
                    <div className="row justify-content-between pb-4 pt-4">
                        <div className="col-lg-4 d-flex align-items-center text-center text-lg-start">
                            <div>
                                <h1 className="fw-bold text-white display-3">intelecto</h1>
                                <p className="py-3 fs-5 text-white">Kao tim želimo da naše stečeno znanje iskoristimo na
                                    što efikasniji način. Zajedničkim djelovanjem odlučili smo da sistem edukacije
                                    unaprijedimo na veći nivo.</p>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3">
                                <a href={'https://aldenhasanovic.me/'} className={'text-decoration-none'} target={"_blank"}><TeamMemberCard
                                    img='./assets/LandingPage/People/alden.jpg'
                                    name='Alden Hasanović'/>
                                </a>
                                <a href={'https://petardev.live/'} className={'text-decoration-none'} target={"_blank"}><TeamMemberCard
                                    img='./assets/LandingPage/People/petar.jpg'
                                    name='Petar Simonović'/>
                                </a>
                                <a href={'#'} className={'text-decoration-none'} target={"_blank"}><TeamMemberCard
                                    img='./assets/LandingPage/People/arman.jpeg'
                                    name='Arman Omerović'/>
                                </a>
                                <a href={'#'} className={'text-decoration-none'} target={"_blank"}><TeamMemberCard
                                    img='./assets/LandingPage/default.png'
                                    name='Milorad Nedović'/>
                                </a>
                                <a href={'#'} className={'text-decoration-none'} target={"_blank"}><TeamMemberCard
                                    img='./assets/LandingPage/default.png'
                                    name='Anes Čoković'/>
                                </a>
                                <a href={'#'} className={'text-decoration-none'} target={"_blank"}><TeamMemberCard
                                    img='./assets/LandingPage/default.png'
                                    name='Slađan Jeremić'/>
                                </a>
                                <a href={'#'} className={'text-decoration-none'} target={"_blank"}><TeamMemberCard
                                    img='./assets/LandingPage/default.png'
                                    name='Neško Gojačanin'/>
                                </a>
                                <a href={'#'} className={'text-decoration-none'} target={"_blank"}><TeamMemberCard
                                    img='./assets/LandingPage/default.png'
                                    name='Nenad Kordić'/>
                                </a>
                                <a href={'#'} className={'text-decoration-none'} target={"_blank"}><TeamMemberCard
                                    img='./assets/LandingPage/default.png'
                                    name='Sofija Simonović'/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
        </div>
    );
}

export default LandingPage;
