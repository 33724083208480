import React, { useState } from "react";
import * as Unicons from "@iconscout/react-unicons";

const CustomRadioSelector = ({ options, onSelect }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    onSelect(option);
  };

  return (
    <div>
      {options.map((option, index) => (
        <div key={index} className="d-flex flex-column">
          {selectedOption === option ? (
            <button
              key={option}
              onClick={() => handleOptionClick(option)}
              className={`radio-button ${
                selectedOption === option ? "selected" : ""
              } text-start d-flex justify-content-between align-items-center bg-primary-subtle bg-opacity-10 border border-primary-subtle border-1 rounded-3 px-3 py-3 mt-2 text-primary`}
            >
              {option}
              {selectedOption === option && (
                <div className="bg-primary rounded-5">
                  <Unicons.UilCheck color="white" size={25} />
                </div>
              )}
            </button>
          ) : (
            <button
              key={option}
              onClick={() => handleOptionClick(option)}
              className={`radio-button ${
                selectedOption === option ? "selected" : ""
              } text-start d-flex justify-content-between align-items-center bg-transparent border border-secondary-subtle border-1 rounded-3 px-3 py-3 mt-2 text-primary-emphasis`}
            >
              {option}

              <div>
                <Unicons.UilCheck
                  color="transparent"
                  size={25}
                  className="border border-secondary rounded-5"
                />
              </div>
            </button>
          )}
        </div>
      ))}
    </div>
  );
};

export default CustomRadioSelector;
