import {Link, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import Validator from "../../classes/Validator";
import Swal from "sweetalert2";
import loginPage from "./LoginPage";
import ApiRequest from "../../classes/ApiRequest";

const RegisterPage = () => {

    const navigator = useNavigate();

    useEffect(() => {
        const storedToken = localStorage.getItem('token');

        if (storedToken) {
            navigator('/threads');
        } else {
            const apiRequest = new ApiRequest('POST', `${process.env.REACT_APP_API_URL}/api/login`, JSON.stringify(userInfo));
            apiRequest.editHeader("Authorization", `Bearer ${process.env.REACT_APP_API_KEY}`);
            apiRequest.sendRequest()
                .then(result => {
                    const parsed = JSON.parse(result);
                    console.log(parsed);
                    if (parsed.success) {
                        localStorage.setItem('token', parsed.data.token);
                        navigator('/threads');
                    } else {

                    }
                })
                .catch(error => {
                    console.error('Login failed:', error);
                });
        }
    }, []);

    let initValidator = {messages: {}};
    const [validator, setValidator] = useState(initValidator)

    const [userInfo, setUserInfo] = useState({
        name: '',
        surname: '',
        email: '',
        username: '',
        password: '',
        password_confirmation: '',
        device: window.navigator.platform
    })

    const formChangeHandler = evt => {
        setValidator(initValidator);
        const name = evt.target.name
        const value = evt.target.value
        setUserInfo((prevState) => {
            return {...prevState, [name]: value.trimStart()}
        })
    }

    const formSubmitHandler = evt => {
        evt.preventDefault()
        let form = evt.target;
        form.classList.add('was-validated');

        let validator = Validator(userInfo, {
            name: ['required', 'max:256'],
            surname: ['required', 'max:10'],
            username: ['required', 'max:90', 'alpha_dash', 'lowercase'],
            email: ['required', 'email'],
            password: ['required', 'min:8', 'max:255', 'confirmed'],
            device: ['required']
        })

        setValidator(validator);

        if (!form.checkValidity()) {
            evt.stopPropagation();
        } else if (validator.passed) {

            const apiRequest = new ApiRequest('POST', `${process.env.REACT_APP_API_URL}/api/register`, JSON.stringify(userInfo))
            apiRequest.editHeader("Authorization", `Bearer ${process.env.REACT_APP_API_KEY}`)
            apiRequest.sendRequest()
                .then(result => {
                    const parsed = JSON.parse(result)
                    console.log('swal stuff', parsed)
                    if(parsed.success) {
                        localStorage.setItem('token', parsed.data.token)
                        navigator('/threads');
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: parsed.data.error,
                        })
                    }
                })
            // .catch(error => console.log(error)) //console.log(error) if something zabode

        }
    }

    return (
        <main className="container-fluid">
            <div className="row flex-md-row-reverse min-vh-100">

                <div className="d-none d-md-block col-12 col-md-6 bg-primary align-items-center">
                    <div className="d-flex flex-column justify-content-center align-items-center h-100">
                        <h1 className={"text-white fw-bold"} style={{fontSize: 38}}>betterEdu</h1>
                        <div className="text-white mt-2" style={{fontSize: '16px'}}>
                            <div className="fw-light">Made with love by <span className="fw-bold">intelecto</span></div>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-6 mt-5 mt-md-0 d-flex justify-content-center align-items-center align-self-md-start align-self-md-center">
                    <div className="container w-495">
                        <form name="register_form" onSubmit={formSubmitHandler} noValidate={true}>
                            <h2 className="mb-2 fw-bold">Registrujte se na betterEdu</h2>
                            <h5 className="mb-4">Popunite polja u formi i kreirajte nalog</h5>
                            <div>
                                <div className="row justify-content-between p-0 m-0 mb-3 gx-3">
                                    <div className="col-6 ps-0">
                                        <input required pattern={"^[a-zA-Z0-9\\s]+$"} type="text" placeholder="Ime"
                                               onChange={formChangeHandler}
                                               value={userInfo.name} name="name"
                                               className="form-control rounded-3 border-0 m-0"/>
                                        <div className="invalid-feedback">{validator.messages.name}</div>
                                    </div>
                                    <div className="col-6 pe-0">
                                        <input required type="text" placeholder="Prezime" onChange={formChangeHandler}
                                               value={userInfo.surname} name="surname"
                                               className="form-control rounded-3 border-0 m-0"/>
                                        <div className="invalid-feedback">{validator.messages.surname}</div>
                                    </div>
                                </div>
                                <div className="m-0 mb-3 w-100">
                                    {/*TODO fix this regex to accept -*/}
                                    <input required pattern={'^[a-z0-9_]+$'} type="text" placeholder="Username"
                                           onChange={formChangeHandler}
                                           value={userInfo.username} name="username"
                                           className="form-control rounded-3 border-0"/>
                                    <div className="invalid-feedback">{validator.messages.username}</div>
                                </div>
                                <div className="m-0 mb-3 w-100">
                                    <input required type="email" placeholder="E-mail" onChange={formChangeHandler}
                                           value={userInfo.email} name="email"
                                           className="form-control rounded-3 border-0"/>
                                    <div className="invalid-feedback">{validator.messages.email}</div>
                                </div>
                                <div className="m-0 mb-3 w-100">
                                    <input required minLength={8} type="password" placeholder="Šifra"
                                           onChange={formChangeHandler}
                                           value={userInfo.password} name="password"
                                           className="form-control rounded-3 border-0"/>
                                    <div className="invalid-feedback">{validator.messages.password}</div>
                                </div>
                                <div className="m-0 mb-3 w-100">
                                    <input required minLength={8} type="password" placeholder="Ponovite šifru"
                                           onChange={formChangeHandler}
                                           value={userInfo.password_confirmation} name="password_confirmation"
                                           className="form-control rounded-3 border-0"/>
                                    <div className="invalid-feedback">{validator.messages.password}</div>
                                </div>
                            </div>
                            <div className="row mb-0 mt-2">
                                <div className="col d-flex">
                                    <input required id="remember_me" type="checkbox" className="form-check-input"/>
                                    <label htmlFor="remember_me"
                                           className="ms-2 fw-medium form-check-label">Prihvatam
                                        <span className="ms-1">
                                            <Link to={'#'}>uslove korišćenja</Link>
                                        </span>
                                    </label>
                                </div>
                                <div className="col d-flex justify-content-end">
                                    <h6 className="fw-medium mb-3 text-end">Imate nalog?
                                        <Link to="/login"
                                            className="text-primary fw-medium text-decoration-none"> Prijavite se</Link>
                                    </h6>
                                </div>
                            </div>
                            <button type="submit"
                                    className="btn btn-primary w-100 mb-3 mt-2 fw-bold fs-5 rounded-3 py-2">Registruj se
                            </button>
                        </form>
                    </div>
                </div>

            </div>
        </main>
    )
}

export default RegisterPage