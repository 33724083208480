import * as Unicons from '@iconscout/react-unicons';
import {useLocation, useNavigate} from "react-router-dom";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import React, {useRef, useEffect, useState} from 'react';
import './NavBarModal';
import NavBarModal from "./NavBarModal";
import SearchBar from "./SearchBar";

const BottomNavBar = (p) => {


    const location = useLocation()
    const path = location.pathname

    console.log(path)

    const labelStyles = {
        fontSize: "12px"
    }

    const labelStylesDestkop = {
        fontSize: "20px"
    }

    const searchState = useState(false)



    const navigator = useNavigate()

    return (
        <div className="min-vh-100 p-0 m-0 row bg-body" data-bs-theme={localStorage.getItem('theme')}>
            <NavBarModal/>
            {/* Sadrzaj strana ide ovde */}
            <div className="row m-0 p-0">
                <div className="d-none col-lg-4 col-md-3 col-12 m-0 p-0 d-md-flex justify-content-lg-end border-1 border-end">
                    <div className="w-50">
                        <div className="position-fixed d-none d-sm-none d-md-inline-block bg-body d-flex justify-content-between pb-2 pt-3">
                            <h2 className={'fw-bold text-primary-emphasis pb-4'}>betterEdu</h2>

                            <div onClick={() => navigator('/threads')}
                                 className="mb-4 cursor-pointer">

                                {path === '/threads' ?
                                    <Unicons.UilCommentsAlt className="text-primary-emphasis fw-bold me-2" size="35"/> :
                                    <Unicons.UilCommentsAlt className="me-2" size="35" color="gray"/>}

                                <span className={path === '/threads' ? 'text-primary-emphasis fw-bold' : 'text-secondary'}
                                      style={labelStylesDestkop}>Forum</span>
                            </div>

                            <div onClick={() => navigator('/resources')}
                                 className="mb-4 cursor-pointer">

                                {path === '/resources' ?
                                    <Unicons.UilNotebooks className="text-primary-emphasis fw-bold me-2" size="35"/> :
                                    <Unicons.UilNotebooks className="me-2" size="35" color="gray"/>}

                                <span className={path === '/resources' ? 'text-primary-emphasis fw-bold' : 'text-secondary'}
                                      style={labelStylesDestkop}>Resursi</span>
                            </div>

                            <div onClick={() => navigator('/reports')}
                                 className="mb-4 cursor-pointer">

                                {path === '/reports' ?
                                    <Unicons.UilExclamationOctagon className="text-primary-emphasis fw-bold me-2" size="35"/> :
                                    <Unicons.UilExclamationOctagon className="me-2" size="35" color="gray"/>}

                                <span className={path === '/reports' ? 'text-primary-emphasis fw-bold' : 'text-secondary'}
                                      style={labelStylesDestkop}>Prijave</span>
                            </div>

                            <div onClick={() => navigator('/profile')}
                                 className="mb-4 cursor-pointer">

                                {path === '/profile' ?
                                    <Unicons.UilUser className="text-primary-emphasis fw-bold me-2" size="35"/> :
                                    <Unicons.UilUser className="me-2" size="35" color="gray"/>}

                                <span className={path === '/profile' ? 'text-primary-emphasis fw-bold' : 'text-secondary'}
                                      style={labelStylesDestkop}>Nalog</span>
                            </div>

                            <div
                                 className="mb-4 cursor-pointer">

                                <div className="d-grid gap-2">
                                    <button className="btn btn-primary btn-lg" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">Kreiraj</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12 m-0 p-0 border-1 border-end">
                    {p.children}
                </div>
                <div className="d-none d-md-flex col-lg-4 col-md-3">
                    <div className="w-100 mt-2">
                        <div className="position-fixed">
                            <h3 className={'fw-bold text-primary-emphasis'}>Pretraga</h3>
                        <SearchBar placeholder="Pretraži"  inputState={searchState}></SearchBar>
                        </div>

                    </div>
                </div>
            </div>

            <div className="d-flex d-sm-flex d-md-none fixed-bottom bg-body d-flex justify-content-between text-center pb-2 pt-3 column-gap-2">

                <div onClick={() => navigator('/threads')}
                     className="row m-0 cursor-pointer">

                    {path === '/threads' ?
                        <Unicons.UilCommentsAlt className="text-primary-emphasis" size="25"/> :
                        <Unicons.UilCommentsAlt size="25" color="gray"/>}

                    <span className={path === '/threads' ? 'text-primary-emphasis' : 'text-secondary'}
                          style={labelStyles}>Forum</span>
                </div>

                <div onClick={() => navigator('/resources')}
                     className="row m-0 cursor-pointer">

                    {path === '/resources' ?
                        <Unicons.UilNotebooks className="text-primary-emphasis" size="25"/> :
                        <Unicons.UilNotebooks size="25" color="gray"/>}

                    <span className={path === '/resources' ? 'text-primary-emphasis' : 'text-secondary'}
                          style={labelStyles}>Resursi</span>
                </div>

                <div onClick={() => navigator('/reports')}
                     className="row m-0 cursor-pointer">

                    {path === '/reports' ?
                        <Unicons.UilExclamationOctagon className="text-primary-emphasis" size="25"/> :
                        <Unicons.UilExclamationOctagon size="25" color="gray"/>}

                    <span className={path === '/reports' ? 'text-primary-emphasis' : 'text-secondary'}
                          style={labelStyles}>Prijave</span>
                </div>

                <div onClick={() => navigator('/profile')}
                     className="row m-0 cursor-pointer">

                    {path === '/profile' ?
                        <Unicons.UilUser className="text-primary-emphasis" size="25"/> :
                        <Unicons.UilUser size="25" color="gray"/>}

                    <span className={path === '/profile' ? 'text-primary-emphasis' : 'text-secondary'}
                          style={labelStyles}>Nalog</span>
                </div>
            </div>

        </div>
    )

}

export default BottomNavBar