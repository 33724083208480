import * as Unicons from '@iconscout/react-unicons';
import {useNavigate} from "react-router-dom";

const ReportCard = (props) => {

    const navigator = useNavigate();
    const navigate = () => navigator(`/report/details`)

    return  (
        <div onClick={navigate}>
            <div className="bg-secondary-subtle col mt-2 pt-1 rounded-3">
                <div className="px-3 text-primary-emphasis mt-2">
                    <div className="fw-medium" style={{fontSize: "16px"}}>{props.description}</div>
                </div>
                <div className="px-3 text-primary-emphasis mt-2 d-flex justify-content-between align-items-center w-100">
                    <div className="pb-3" >
                        <Unicons.UilExclamationOctagon size='26' color={props.color}/>
                        <span className="ps-2 fw-semibold" style={{fontSize: "12px"}}>{props.date}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReportCard