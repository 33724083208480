import React, {useState} from "react";
import * as Unicons from "@iconscout/react-unicons";
import PropTypes from "prop-types";
import ActivityButton from "./ActivityButton";
import {useNavigate} from "react-router-dom";

const ThreadComment = (p) => {

    const navigator = useNavigate()

    const reply = p.reply;
    const answerCount = p.reply.replies.length;

    let displayedPfps

    const [likes, setLikes] = useState(reply.likes)

    return (
        <div className="container-fluid border-bottom border-secondary-subtle pb-4 user-select-none">
            <div className="row my-2">
                <div className="col d-flex">
                    <div className="justify-content-end align-items-center d-flex me-2 my-2 m-0">
                        <img className="rounded-circle" src={reply.user.photoPath} alt="" style={{height: "40px"}}/>
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                        <div className="fw-medium text-primary-emphasis" style={{fontSize: "14px"}}>{reply.user.name + ' ' + reply.user.surname}</div>
                        <div className="text-primary-emphasis" style={{fontSize: "12px"}}>@{reply.user.username}</div>
                    </div>
                </div>
                <div className="col justify-content-end align-items-center d-flex p-2 m-0">
                    {/*TODO: Vratiti isAnswer na replyu*/}
                    {false === true ? (
                        <Unicons.UilCheckCircle
                            color="#2DE000"
                            size="20"
                            className="text-primary-emphasis"
                    />) : null}
                    <Unicons.UilEllipsisV
                        size="20"
                        className="text-primary-emphasis"
                    />
                </div>
            </div>

            <div className="d-flex flex-column">
                <div className="d-flex column-gap-4">
                    {answerCount > 0 ?
                        <div style={{width: '2px'}} className="border border-1 border-secondary-subtle ms-3"/> : null}
                    <div className="w-100">

                        <div onClick={() => navigator(`/thread/${reply.id}`)} className="p-0">
                            <div className="text-primary-emphasis fw-light" style={{fontSize: '14px'}}>{reply.description}</div>

                            {reply.media.length !== 0 ?
                                <div className="rounded-2 overflow-hidden position-relative mt-2 mb-4" style={{maxHeight: '150px'}}>
                                    <img className="w-100 translate-middle-y" src={reply.media[0].path} alt=""/>
                                </div> : <div className={"m-3"}></div>}

                        </div>

                        <div className="d-flex justify-content-between align-items-center">
                            <div className="mt-2 text-primary-emphasis" style={{fontSize: "13px"}}>
                                <div><b>{likes}</b> likes</div>
                            </div>
                            <div className="d-flex column-gap-3 text-primary-emphasis">
                                <div className="cursor-pointer" onClick={() => navigator(`/thread/reply/${reply.id}`)}>
                                    <Unicons.UilComment size="25"></Unicons.UilComment>
                                </div>
                                <ActivityButton size="25" id={reply?.id} type="save" contentType="forums" isActive={reply?.isSaved}/>

                                <Unicons.UilShare size="25" />

                                <ActivityButton size="25" id={reply?.id} type="like" contentType="forums" setLikes={setLikes}
                                                isActive={reply?.isLiked}/>

                            </div>
                        </div>
                    </div>
                </div>
                {answerCount > 0 ?
                    <div className="d-flex align-items-center mt-3 position-relative mt-4">
                        {reply.replies.map((reply, i) => {
                            if (i < 3) {
                                displayedPfps = i + 1
                                return (
                                    <div key={i} className={`z-${i} position-absolute`} style={{left: `${i * 2}0px`}}>
                                        <img height="30" src={reply.user.photoPath} alt="" className="rounded-circle"/>
                                    </div>
                                )
                            } else {
                                return null
                            }
                        })}
                        <div className="text-primary position-absolute ms-3"
                             style={{fontSize: '12px', left: `${displayedPfps * 2}0px`}}>{answerCount} odgovora
                        </div>
                    </div> : null}
            </div>


        </div>
    );
};

export default ThreadComment;
