import UilArrowLeft from "@iconscout/react-unicons/icons/uil-arrow-left";
import UilPaperclip from "@iconscout/react-unicons/icons/uil-paperclip";
import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import UilTagAlt from "@iconscout/react-unicons/icons/uil-tag-alt";
import ResourceTagPicker from "../../components/ResourceTagPicker";
import CategoryButton from "../../components/CategoryButton";
import AWS from 'aws-sdk';
import ApiRequest from "../../classes/ApiRequest";
import S3Uploader from "../../classes/S3Uploader";

//TODO: zavrsi edit da ne zaboravis armane

const EditResourcePage = () => {

    const navigator = useNavigate()
    const params = useParams()

    const [tagPickerVisible, setTagPickerVisible] = useState(false)

    const [tags, setTags] = useState([])
    const tagState = [tags, setTags]

    const [file, setFile] = useState(null)
    const [resource, setResource] = useState(null)

    useEffect(() => {
        const fileDisplayArea = document.getElementById('fileDisplayArea')

        if (file) {
            const reader = new FileReader();

            reader.onload = function(event) {
                const dataURL = event.target.result;

                fileDisplayArea.innerHTML = file.type.startsWith('image/')
                    ? `<img src="${event.target.result}" alt="Selected File" class="w-100 image-container"/>`
                    : `<pre class="text-primary-emphasis px-2">${file.name}</pre>`;

                // console.log(event)
            }
                reader.readAsDataURL(file);
        }

    }, [file]);

    useEffect(() => {
        const apiRequest = new ApiRequest('GET', `${process.env.REACT_APP_API_URL}/api/resources/${params.id}`)
        apiRequest.sendRequest()
            .then(result => setResource(() => JSON.parse(result).data))
            .catch(error => console.log('Error@EditResourcePage', error))
    }, []);


    const formSubmitHandler = async (e) => {
        e.preventDefault()

        const raw = {
            "description": resource?.description,
            "title": resource?.title,
            "categories": tags.map(tag => tag.id)
        };

        AWS.config.update({
            accessKeyId: process.env.REACT_APP_S3_ACCESS,
            secretAccessKey: process.env.REACT_APP_S3_SECRET,
        });

        const s3 = new AWS.S3({
            params: {Bucket: process.env.REACT_APP_S3_BUCKET},
            region: process.env.REACT_APP_S3_BUCKET_REGION
        })

        if (file) {
            new S3Uploader(file).upload()
                .then(data => {
                    console.log('Successfully uploaded:', data.Location);
                    raw.media = [data.Location]
                    const apiRequest = new ApiRequest('PATCH', `${process.env.REACT_APP_API_URL}/api/resources/${params.id}`, JSON.stringify(raw));
                    apiRequest.sendRequest()
                        .then(result => {console.log(JSON.parse(result))})
                        .catch(error => console.log('Error@EditResourcePage', error));
                })
                .catch(err => {
                    console.error('Error uploading:', err);
                });

        } else {
            const apiRequest = new ApiRequest('PATCH', `${process.env.REACT_APP_API_URL}/api/resources/${params.id}`, JSON.stringify(raw));
            apiRequest.sendRequest()
                .then(result => {navigator(-1)})
                .catch(error => console.log('Error@EditResourcePage', error));
        }
    }

    const formChangeHandler = evt => {
        const name = evt.target.name
        const value = evt.target.value
        setResource((prevState) => {
            return {...prevState, [name]: value.trimStart()}
        })
    }

    const tagChangeHandler = evt => {
        if (!tags.some((tag) => tag.id === evt.target.name)) {
            setTags((prevState) => {
                return [...prevState, {id: evt.target.name, name: evt.target.innerHTML}]
            })
            evt.target.style.opacity = '0.2'
        } else {
            setTags((prevState) => {
                return [...prevState.filter((obj) => obj.id !== evt.target.name)]
            })
            evt.target.style.opacity = '1'
        }
    }

    const handleFileChange = (e) => {

        setFile(e.target.files[0]);
    };


    console.log(resource)
    return (
        <div className="container-fluid bg-body min-vh-100 p-0 d-flex flex-column"
             data-bs-theme={localStorage.getItem('theme')}>
            <form onSubmit={formSubmitHandler}>

                {tagPickerVisible ? <ResourceTagPicker setTagPickerVisible={setTagPickerVisible}
                                                       tagChangeHandler={tagChangeHandler}
                                                       tagState={tagState}/> : null}

                <div className="d-flex p-1 align-items-center justify-content-between">
                    <div onClick={() => navigator(-1)}>
                        <UilArrowLeft size="50" className="text-primary-emphasis"/>
                    </div>
                    <div>
                        <button type="button"
                                onClick={() => {
                                    tagPickerVisible ? setTagPickerVisible(false) : setTagPickerVisible(true)
                                }}
                                className={`btn py-2 px-3 me-2 rounded-3
                                ${localStorage.getItem('theme') === 'light' ? 'bg-black text-white' : 'bg-white text-black'}`}
                                style={{fontSize: '14px'}}>
                            Tag <span className="ms-1"><UilTagAlt size="18"/></span>
                        </button>
                        <button type="submit"
                                className="btn btn-primary text-white py-2 px-3 me-2 rounded-3"
                                style={{fontSize: '14px'}}>Publish
                        </button>
                    </div>
                </div>

                {tags ?
                    <div className="d-flex overflow-x-scroll my-2 px-3">
                        {tags.map((category, index) =>
                            <div key={index}
                                 className={index === 0 ? ' me-1' : 'mx-1'}>
                                <CategoryButton>{category.name}</CategoryButton>
                            </div>)}
                    </div>
                    : null
                }

                <div className="px-2">
                    <input type="text"
                           name="title"
                           className="w-75 bg-body text-primary-emphasis border-0 gray-placeholder search-input ms-1"
                           placeholder="Title..."
                           defaultValue={resource?.title}
                           onChange={formChangeHandler}
                           style={{fontSize: "24px"}}/>
                </div>
                <div className="d-flex px-3 align-items-start justify-content-between mt-1">
                    <div className="w-100 d-flex align-items-start">
                        <img src="/logo512.png" alt="" style={{width: '40px'}}/>
                        <textarea name="description"
                                  className="w-75 bg-body text-primary-emphasis border-0 gray-placeholder search-input mt-2 ms-1"
                                  placeholder="Description..."
                                  defaultValue={resource?.description}
                                  onChange={formChangeHandler}
                                  style={{height: '300px'}}/>
                    </div>
                    <div>
                        <label htmlFor="upload">
                            <UilPaperclip size="30" className="text-primary-emphasis"/>
                            <input id="upload" name="upload" type="file" style={{display: 'none'}}
                                   onChange={handleFileChange}/>
                        </label>
                    </div>
                </div>
                {file ? <div className="text-primary-emphasis px-2">Attachments: </div> : null}
                <div id="fileDisplayArea" className="px-2 d-flex flex-column column-gap-2 row-gap-2">

                </div>
            </form>
        </div>
    )
}

export default EditResourcePage